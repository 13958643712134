<script>
import { LogOutIcon } from 'vue-feather-icons';
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';
import BaseButton from '@/shared/components/BaseButton.vue';
import Vue from 'vue';
import VPopover from 'vue-js-popover';
import i18n from '../plugins/vue-i18n';

Vue.use(VPopover, { tooltip: true });
export default {
  name: 'Profile',
  components: {
    BaseButton,
    LogOutIcon,
  },
  props: {},
  data() {
    return {
      page: 'view_profile',
      image: '/media/user/profile.svg',
      name: '',
      email: '',
      language: window.localStorage.getItem('language'),
      feedbackMessage: '',
      password: '',
      password_confirm: '',
      showPassword: false,
      showPasswordConfirm: false,
      dropped: 0,
      error: '',
      newImage: '',
      code: '',
      startRemoveData: false,
      passwordLength: false,
      containsNumbers: false,
      containsLetter: false,
      containsSymbols: false,
      valid: false,
      validConfirm: false,
    };
  },
  methods: {
    openProfile() {
      const openProfile = document.querySelector('.profile-modal');
      openProfile.classList.toggle('show');
      if (openProfile.classList.value.includes('hide')) {
        openProfile.classList.toggle('hide');
      } else {
        setTimeout(() => {
          openProfile.classList.toggle('hide');
        }, 300);
      }
    },
    getProfile() {
      const getProfile = '/WebGetProfile.php';
      const data = new FormData();
      fetch(server + getProfile, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'error') {
          if (json.error.includes('Wrong token')) {
            this.$router.push({ name: 'login' });
            destroyToken();
          } else {
            Notify('warning', json.error);
          }
        } else {
          this.name = json.name;
          this.email = json.email;
          if (json.image) {
            this.image = json.image;
          }
        }
      });
    },
    sendFeedback() {
      const sendFeedback = '/WebSendFeedback.php';
      const data = new FormData();
      data.append('message', this.feedbackMessage);
      data.append('name', this.name);
      fetch(server + sendFeedback, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'error') {
          if (json.error.includes('Wrong token')) {
            this.$router.push({ name: 'login' });
            destroyToken();
          } else {
            Notify('warning', json.error);
          }
        } else {
          Notify('success', i18n.t('GENERAL.SAVED_SUCCESSFULLY'));
          this.page = 'view_profile';
          this.feedbackMessage = '';
        }
      });
    },
    editProfile() {
      if (this.password.length !== 0 && (this.valid === false || this.validConfirm === false)) {
        Notify('warning', i18n.t('AUTH.ERROR_PASSWORD'));
        return;
      }
      const sendFeedback = '/WebEditProfile.php';
      const data = new FormData();
      data.append('email', this.email);
      data.append('name', this.name);
      data.append('image', this.newImage);
      if (this.password !== '' && this.password === this.password_confirm) {
        data.append('password', this.password);
      }
      fetch(server + sendFeedback, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'error') {
          if (json.error.includes('Wrong token')) {
            this.$router.push({ name: 'login' });
            destroyToken();
          } else {
            Notify('warning', json.error);
          }
        } else {
          Notify('success', i18n.t('GENERAL.SAVED_SUCCESSFULLY'));
          this.page = 'view_profile';
          this.password = '';
          this.password_confirm = '';
        }
      });
    },
    removeMyDataStart() {
      const link = '/WebRemoveMyDataStart.php';
      const data = new FormData();
      fetch(server + link, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status) {
          this.startRemoveData = true;
        }
      });
    },
    removeMyDataFinish() {
      const link = '/WebRemoveMyDataFinish.php';
      const data = new FormData();
      data.append('code', this.code);
      fetch(server + link, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status) {
          this.$router.push({ name: 'login' });
          destroyToken();
        }
      });
    },
    logout() {
      this.$router.push({ name: 'login' });
      destroyToken();
    },
    readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = function y() {
          resolve(fr.result);
        };
        fr.onerror = function z() {
          reject(fr);
        };
        fr.readAsDataURL(file);
      });
    },
    previewImgs(event) {
      this.files = event.currentTarget.files;
      this.$emit('changed', this.files);
      const readers = [];
      if (!this.files.length) return;
      for (let i = 0; i < this.files.length; i += 1) {
        readers.push(this.readAsDataURL(this.files[i]));
      }
      Promise.all(readers).then((values) => {
        console.log(values);
        this.newImage = values;
        this.image = null;
      });
    },
    handleChangePassword(e) {
      const number = e.target.value.match(/\d+/g);
      const letters = e.target.value.match(/[a-z]/);
      const symbols = new RegExp(/[^A-Z a-z0-9]/);

      this.passwordLength = e.target.value.length > 7;
      this.containsNumbers = number != null;
      this.containsLetter = letters != null;
      this.containsSymbols = symbols.test(e.target.value);
      this.valid = e.target.value.length > 7 && number != null
          && letters != null && symbols.test(e.target.value);
      this.validConfirm = e.target.value === this.password_confirm;
    },
    handleChangePasswordConfirm(e) {
      this.validConfirm = e.target.value === this.password;
    },
  },
  beforeMount() {
    this.getProfile();
  },
};
</script>
<template>
  <div class="profile-modal">
    <div class="add-item-left" v-on:click="openProfile">
    </div>
    <div class="add-item-window">
      <!--      <form class="form" v-on:submit.prevent="addCategoryFunc(1)"
                  id="add_dep_form" style="width: 100%;">-->

      <div style="height: 100vh">
        <div class="menu">
          <unicon name="multiply" fill="#828282" height="24" width="24"
                  v-on:click="openProfile"/>
          <div class="title" style="width: calc(100% - 40px)">
            {{ $t('USER.ACCOUNT_SETTINGS') }}
          </div>
        </div>
        <div class="content" v-if="page === 'view_profile'">
          <b-row>
            <b-col>
              <div>
                <div>
                  <div style="display: flex; vertical-align: center">
                    <div class="profile-image">
                      <img :src="image"/>
                    </div>
                    <div class="title-2-bold-20 basic-1 m-0" style="align-items: center;
                    vertical-align: center; display: inline-flex">
                      {{ name }}
                    </div>
                  </div>
                </div>
                <div class="mt-3 basic-1 headline-medium-16">
                  <unicon name="envelope" width="16" height="16" fill="#333333"></unicon>
                  {{ email }}
                </div>
              </div>
              <div class="form-separator mt-4 mb-4" style="height: 1px">
              </div>
              <div>
                <div class="basic-1 title-2-bold-20">
                  {{ $t('USER.EXTRAS') }}
                </div>
                <div class="headline-regular-16 basic-1 mb-5">
                  <img src="/media/user/HandWaving.svg" alt="">
                  {{ $t('USER.INVITE_FRIENDS_ON_EDNEOS') }}
                </div>
                <div class="headline-regular-16 basic-1 mb-2">
                  <unicon name="lightbulb" width="16" height="16" fill="#333333"></unicon>
                  {{ $t('USER.EDNEOS_TIPS') }}
                </div>
                <div class="headline-regular-16 basic-1 mb-5">
                  <div style=" margin-left: 40px">
                      <span style="font-weight: bold">
                        {{ $t('USER.SPECIAL_OFFER') }}
                      </span>
                    {{ $t('USER.SPECIAL_OFFER_DETAILS') }}
                    <span style="font-weight: bold; color: #FDAB3D">
                          {{ $t('USER.UPGRADE_NOW') }}
                        </span>
                  </div>
                </div>
                <div class="headline-regular-16 basic-1 mb-5">
                  <unicon name="shopping-bag" width="16" height="16" fill="#333333"></unicon>
                  {{ $t('USER.MY_BENEFITS') }}
                </div>
                <div class="headline-regular-16 basic-1 mb-5" style="cursor: pointer"
                     v-on:click="page = 'feedback'">
                  <unicon name="chat" width="16" height="16" fill="#333333"></unicon>
                  {{ $t('USER.FEEDBACK') }}
                </div>

              </div>

              <div class="form-separator mt-4 mb-4" style="height: 1px">
              </div>
              <div>

                <div class="basic-1 title-2-bold-20">
                  {{ $t('USER.SETTINGS') }}
                </div>
                <div class="headline-regular-16 basic-1 mb-5" style="cursor: pointer"
                     v-on:click="page = 'edit_profile'">
                  <unicon name="user" width="16" height="16" fill="#333333"></unicon>
                  {{ $t('USER.MY_PROFILE') }}
                </div>
                <div class="headline-regular-16 basic-1 mb-5" style="cursor: pointer"
                     v-on:click="page = 'manage_profile'">
                  <img src="/media/user/Kanban.svg" alt="">
                  {{ $t('USER.MANAGE_ACCOUNT') }}
                </div>
                <div class="headline-regular-16 basic-1 mb-5" style="cursor: pointer"
                     v-on:click="page = 'terms'">
                  <unicon name="file-alt" width="16" height="16" fill="#333333"></unicon>
                  {{ $t('USER.TERMS_AND_CONDITIONS') }}
                </div>
                <div class="headline-regular-16 basic-1 mb-5" style="cursor: pointer"
                     v-on:click="page = 'privacy'"
                >
                  <unicon name="shield-exclamation" width="16" height="16" fill="#333333">
                  </unicon>
                  {{ $t('USER.PRIVACY_POLICY') }}
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="content" v-if="page === 'feedback'">
          <b-row style="height: 100%">
            <b-col>
              <div class="mb-5">
                <div style="display: flex; vertical-align: center">
                  <div v-on:click="page = 'view_profile'" style="
                    display: inline-flex;cursor: pointer; vertical-align: center;
                    align-items: center;
">
                    <unicon name="arrow-left" width="30" height="30" fill="#333333"
                            style="margin-right: 8px"/>
                    <div class="d-inline-flex">
                      <div class="profile-image">
                        <img :src="image"/>
                      </div>
                      <div class="title-2-bold-20 basic-1 m-0" style="align-items: center;
                    vertical-align: center; display: inline-flex">
                        {{ name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <form class="form" v-on:submit.prevent="sendFeedback()"
                    id="add_dep_form" style="width: 100%;height: calc(100% - 68px);">
                <div class="custom-input">
                    <textarea type="text"
                              v-model="feedbackMessage"
                              required
                              :placeholder="$t('CHAT.TYPE_HERE')"
                              maxlength="200"
                    />
                </div>
                <div style="display: flex; justify-content: center; width: 100%;
                  margin-top: calc(100vh - 310px)">
                  <BaseButton
                      :variant="'primary'"
                      type="submit">
                    {{ $t("GENERAL.SAVE") }}
                  </BaseButton>
                </div>
              </form>
            </b-col>
          </b-row>
        </div>
        <div class="content" v-if="page === 'edit_profile'">
          <b-row>
            <b-col>
              <div class="mb-5">
                <div style="display: flex; vertical-align: center">
                  <div v-on:click="page = 'view_profile'" style="
                    display: inline-flex;cursor: pointer; vertical-align: center;
                    align-items: center;
">
                    <unicon name="arrow-left" width="30" height="30" fill="#333333"
                            style="margin-right: 8px"/>
                    <div class="d-inline-flex">
                      <div class="profile-image">
                        <img :src="image"/>
                      </div>
                      <div class="title-2-bold-20 basic-1 m-0" style="align-items: center;
                    vertical-align: center; display: inline-flex">
                        {{ name }}
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <form class="form" v-on:submit.prevent="editProfile()"
                    id="add_dep_form" style="width: 100%;height: calc(100% - 68px);">
                <div class="upload-photo profile" style="width: 80px; height: 80px;
                  background-color: transparent">
                  <img :src="image ?? newImage" alt=""
                       v-if="newImage || image" style="height: 80px;width: 80px"/>
                  <unicon class="unicon-image" name="image"
                          v-if="newImage && image"
                          width="80" height="80" fill="white"/>
                  <div class="upload-photo-over">
                    <input
                        type="file"
                        style="z-index: 1;opacity: 0;position: absolute;bottom: 0;
                      height: 80px;width: 80px;"
                        accept="image/*"
                        ref="uploadInput"
                        @change="previewImgs"
                        multiple
                    />
                    <unicon name="camera" width="40" height="40" fill="white"/>
                  </div>
                </div>
                <div class="mb-4">
                  <input type="text"
                         class="form-control"
                         v-model="name">
                </div>
                <div class="mb-4">
                  <input type="text"
                         class="form-control"
                         v-model="email">
                </div>

                <div
                    v-popover:password
                    v-popover:info.left
                    v-popover.left="{ name: 'password' }"
                    class="mb-4" style="position: relative"
                    :key="showPassword">
                  <input v-if="showPassword" type="text"
                         :class="'form-control ' + (this.valid ? '' : 'input-invalid')"
                         v-model="password"
                         :placeholder="$t('USER.FIELDS.PASSWORD')"
                         v-b-visible="true"
                         v-on:change="handleChangePassword"
                  >
                  <input v-else type="password"
                         :class="'form-control ' + (this.valid ? '' : 'input-invalid')"
                         v-model="password"
                         :placeholder="$t('USER.FIELDS.PASSWORD')"
                         v-b-visible="true"
                         v-on:change="handleChangePassword"
                  >
                  <div class="control" style="position: absolute; top: 12px; right: 12px">
                    <unicon @click="() => showPassword = !showPassword"
                            :name="showPassword ? 'eye-slash' : 'eye'" fill="#9A9FA7"/>

                  </div>
                </div>
                <popover name="password" position="left" :key="password">
                  <div class="rs-tooltip-inner">
                    <h6 style="font-size: 0.9rem;">
                      {{ $t('GENERAL.PASSWORD_MUST_CONTAIN') }}</h6>
                    <div class="criteria" style="display: flex; padding-top: 5px;">
                      <unicon :name="passwordLength ? 'check' : 'multiply'"
                              width="16" height="16" :fill="passwordLength ? 'rgb(5, 183, 10)'
                               : 'rgb(212, 40, 4)'"></unicon>
                      <p>{{ $t('GENERAL.AR_LEAST_6_CHARACTERS') }}</p></div>
                   <div class="criteria" style="display: flex; padding-top: 5px;">
                     <unicon :name="containsLetter ? 'check' : 'multiply'"
                             width="16" height="16" :fill="containsLetter ? 'rgb(5, 183, 10)'
                               : 'rgb(212, 40, 4)'"></unicon>
                      <p>{{ $t('GENERAL.AR_LEAST_ONE_LETTER') }}</p></div>
                    <div class="criteria" style="display: flex; padding-top: 5px;">
                      <unicon :name="containsNumbers ? 'check' : 'multiply'"
                              width="16" height="16" :fill="containsNumbers ? 'rgb(5, 183, 10)'
                               : 'rgb(212, 40, 4)'"></unicon>
                      <p>{{ $t('GENERAL.AR_LEAST_ONE_NUMBER') }}</p></div>
                    <div class="criteria" style="display: flex; padding-top: 5px;">
                      <unicon :name="containsSymbols ? 'check' : 'multiply'"
                              width="16" height="16" :fill="containsSymbols ? 'rgb(5, 183, 10)'
                               : 'rgb(212, 40, 4)'"></unicon>
                      <p>{{ $t('GENERAL.AR_LEAST_ONE_TOKEN') }}</p></div>
                  </div>
                </popover>
                <div class="mb-4" style="position: relative" :key="showPasswordConfirm">
                  <input v-if="showPassword" type="text"
                         :class="'form-control ' + (this.validConfirm ? '' : 'input-invalid')"
                         v-model="password_confirm"
                         :placeholder="$t('USER.FIELDS.PASSWORD_CONFIRM')"
                         v-b-visible="true"
                         v-on:change="handleChangePasswordConfirm"
                  >
                  <input v-else type="password"
                         :class="'form-control ' + (this.validConfirm ? '' : 'input-invalid')"
                         v-model="password_confirm"
                         :placeholder="$t('USER.FIELDS.PASSWORD_CONFIRM')"
                         v-b-visible="true"
                         v-on:change="handleChangePasswordConfirm"
                  >
                  <div class="control" style="position: absolute; top: 12px; right: 12px">
                    <unicon @click="() => showPasswordConfirm = !showPasswordConfirm"
                            :name="showPasswordConfirm ? 'eye-slash' : 'eye'" fill="#9A9FA7"/>

                  </div>
                </div>
                <div style="display: flex; justify-content: center; width: 100%;
                  margin-top: calc(100vh - 550px)">
                  <BaseButton
                      :variant="'primary'"
                      type="submit"
                      :aria-disabled="(password.length !== 0 && valid) || password.length ===0"
                  >
                    {{ $t("GENERAL.SAVE") }}
                  </BaseButton>
                </div>
              </form>
            </b-col>
          </b-row>
        </div>
        <div class="content" v-if="page === 'manage_profile'">
          <b-row>
            <b-col>
              <div class="mb-5">
                <div style="display: flex; vertical-align: center">
                  <div v-on:click="page = 'view_profile'" style="
                    display: inline-flex;cursor: pointer; vertical-align: center;
                    align-items: center;
">
                    <unicon name="arrow-left" width="30" height="30" fill="#333333"
                            style="margin-right: 8px"/>
                    <div class="d-inline-flex">
                      <div class="profile-image">
                        <img :src="image"/>
                      </div>
                      <div class="title-2-bold-20 basic-1 m-0" style="align-items: center;
                    vertical-align: center; display: inline-flex">
                        {{ name }}
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div v-if="startRemoveData">
                <div class="custom-input mb-4">

                  <label>
                    {{ $t('GENERAL.EMAIL_CODE') }}</label>
                  <input type="text"
                         class="form-control"
                         :value="code"
                         v-on:change="(e) => {
                           this.code = e.target.value
                         }">
                </div>
                <div style="display: flex; justify-content: center; width: 100%;
                  margin-top: calc(100vh - 280px)">
                  <BaseButton
                      :variant="'primary'"
                      type="button"
                      :callback="removeMyDataFinish"
                      :callbackParams="1"
                  >
                    {{ $t("USER.DELETE_ACCOUNT") }}
                  </BaseButton>
                </div>
              </div>
              <div v-else>

                <div class="mt-3 basic-1 headline-medium-16"
                     style="cursor: pointer"
                     v-on:click="removeMyDataStart()">
                  <unicon name="trash-alt" width="16" height="16" fill="#333333"></unicon>
                  {{ $t('USER.DELETE_ACCOUNT') }}
                </div>

                <div class="mt-3 basic-1 headline-medium-16" style="cursor: pointer"
                     v-on:click="logout()">
                  <log-out-icon color="#333333" style="width: 16px; height: 16px;
                                margin-left: 2px"
                                class="custom-class"></log-out-icon>
                  {{ $t('GENERAL.LOGOUT') }}
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="content" v-if="page === 'privacy' && language === 'ro'">
          <b-row>
            <b-col>

              <div class="mb-5">
                <div style="display: flex; vertical-align: center">
                  <div v-on:click="page = 'view_profile'" style="
                    display: inline-flex;cursor: pointer; vertical-align: center;
                    align-items: center;
">
                    <unicon name="arrow-left" width="30" height="30" fill="#333333"
                            style="margin-right: 8px"/>
                    <div class="d-inline-flex">
                      <div class="profile-image">
                        <img :src="image"/>
                      </div>
                      <div class="title-2-bold-20 basic-1 m-0" style="align-items: center;
                    vertical-align: center; display: inline-flex">
                        {{ name }}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="Privacy_container__TkhHO">
                <p><b>Politica de Confidențialitate Edneos</b></p>
                <p><b>1. Despre această Politică de Confidențialitate
                </b></p>
                <p style="text-indent: 5vw;">Vă mulțumim că ați ales aplicația
                  mobilă EdNeos ("Aplicația") și/sau platforma web EdNeos ("Platforma Web"). Această
                  Politică de Confidențialitate explică modul în care colectăm, utilizăm și protejăm
                  informațiile dvs. personale atunci când utilizați Aplicația/Platforma Web. Prin
                  utilizarea Aplicației/Platformei Web, sunteți de acord cu practicile descrise în
                  această Politică de Confidențialitate. Dacă nu sunteți de acord cu practicile
                  descrise mai jos, vă rugăm să nu utilizați Aplicația/Platforma Web.</p>
                <p style="text-indent: 5vw;">Edneos este un grup global de tehnologie educațională
                  care oferă o platformă de învățare interactivă. Ne concentrăm pe dezvoltarea unor
                  instrumente de învățare captivante, atât pentru sectorul educațional, cât și
                  pentru utilizatorii individuali.</p>
                <p><b>1.1. Informațiile Personale
                  Procesate de Edneos</b></p>
                <p style="text-indent: 5vw;">Colectăm și procesăm minim
                  informații personale în funcție de modul în care interacționați cu Edneos,
                  setările
                  contului și produsele pe care le utilizați. Aceste informații includ:</p>
                <p
                    style="text-indent: 10vw;">● Informații Furnizate de dvs.: Nume, adresă de
                  e-mail.
                </p>
                <p style="text-indent: 10vw;">● Date de Comunicare: Informații partajate prin
                  interacțiunea cu serviciile EdNeos, cum ar fi comentariile pe platformă.</p>
                <p><b>2. Utilizarea Informațiilor Personale</b></p>
                <p style="text-indent: 5vw;">Utilizăm informațiile colectate pentru a furniza și
                  îmbunătăți serviciile Edneos, pentru a comunica cu dvs. și pentru a răspunde
                  solicitărilor de asistență.</p>
                <p style="text-indent: 5vw;">Edneos implementează
                  măsuri de securitate pentru a proteja datele personale împotriva accesului
                  neautorizat și a utilizării ilegale.</p>
                <p style="text-indent: 5vw;">Aveți dreptul
                  să accesați, să rectificați sau să ștergeți informațiile personale pe care le
                  deținem despre dvs. și să restricționați prelucrarea acestora în meniul Setări</p>
                <p><b>3. Informații pe care le Colectăm
                  Automat</b></p>
                <p style="text-indent: 5vw;">În cadrul EdNeos,
                  respectăm legislația privind protecția datelor și considerăm anumite informații
                  ca fiind personale conform legilor aplicabile. Colectăm automat diferite tipuri
                  de date în timpul utilizării serviciului nostru, inclusiv:</p>
                <p>
                  <b>3.1. Informații despre Dispozitiv și Browser:</b></p>
                <p
                    style="text-indent: 5vw;">● Tipul computerului (Windows sau Macintosh).</p>
                <p style="text-indent: 5vw;">● Rezoluția ecranului.</p>
                <p style="text-indent: 5vw;">
                  ● Numele și versiunea sistemului de operare.</p>
                <p style="text-indent: 5vw;">
                  ● Producătorul și modelul dispozitivului.</p>
                <p style="text-indent: 5vw;">● Limba.
                </p>
                <p style="text-indent: 5vw;">● Tipul și versiunea browserului de internet.</p>
                <p style="text-indent: 5vw;">● Versiunea aplicației Edneos utilizate.</p>
                <p><b>3.2. Date de Utilizare:</b></p>
                <p style="text-indent: 5vw;">● Activitățile
                  de navigare și interacțiune cu serviciul (cum ar fi secțiunile utilizate).</p>
                <p style="text-indent: 5vw;">● Informații privind performanța serviciilor,
                  inclusiv aspecte legate de livrarea e-mailurilor și alte comunicări.</p>
                <p style="text-indent: 5vw;">● Date privind frecvența utilizării aplicației
                  și alte date de performanță</p>
                <p><b>3.3. Date de Jurnal:</b>
                </p>
                <p style="text-indent: 5vw;">● Înregistrări ale accesărilor pe serverele
                  noastre web, inclusiv adresele IP de origine.</p>
                <p style="text-indent: 5vw;">
                  ● Activitatea în servicii, cum ar fi ștampilele de dată/oră, interacțiunile cu
                  caracteristicile aplicației și alte acțiuni efectuate.</p>
                <p><b>
                  3.4. Cookie-uri și Tehnologii de Urmărire:</b></p>
                <p style="text-indent: 5vw;">
                  ● Colectăm informații prin cookie-uri și tehnologii similare pentru a înțelege
                  cum este utilizat serviciul nostru.</p>
                <p style="text-indent: 5vw;">● Etichetele
                  pixeli sunt folosite pentru a urmări acțiunile utilizatorilor și a măsura
                  eficiența campaniilor de marketing.</p>
                <p><b>4. Drepturile Dumneavoastră de Protecție a Datelor cu EdNeos</b></p>
                <p><b>4.1. Cunoașteți-vă Drepturile</b></p>
                <p style="text-indent: 5vw;">
                  În funcție de jurisdicția în care locuiți, puteți avea următoarele drepturi de
                  protecție a datelor:</p>
                <p style="text-indent: 10vw;">● Accesați, corectați,
                  actualizați, transferați, ștergeți, restrângeți sau vă opuneți prelucrării
                  informațiilor personale de către Edneos.</p>
                <p style="text-indent: 10vw;">
                  ● Retragerea consimțământului pentru prelucrarea bazată pe consimțământ, fără
                  a afecta legalitatea prelucrării efectuate înainte de retragere.</p>
                <p><b>Exercitarea Drepturilor</b></p>
                <p style="text-indent: 5vw;">Pentru a vă
                  exercita drepturile, ne puteți contacta la hello@edneos.com. Vă răspundem la
                  solicitările de exercitare a drepturilor de protecție a datelor în conformitate
                  cu legislația aplicabilă. Vă putem solicita să vă verificați identitatea pentru
                  a procesa cererea dvs. eficient.</p>
                <p><b>4.2. Partajarea
                  Informațiilor Personale</b></p>
                <p style="text-indent: 5vw;">EdNeos nu
                  împărtășește informații personale cu terțe părți, cu excepția cazurilor
                  limitate descrise mai jos:</p>
                <p style="text-indent: 10vw;">● Afiliați
                  și Furnizori de Servicii: Pot fi partajate cu companii afiliate sau furnizori
                  de servicii care prestează servicii în numele nostru, cum ar fi găzduirea web,
                  suportul tehnic, procesarea plăților și alte servicii similare.</p>
                <p
                    style="text-indent: 10vw;">● Parteneri Rețele Sociale: Pot fi utilizate în
                  cooperare cu rețelele sociale pentru promovarea serviciilor EdNeos.</p>
                <p style="text-indent: 10vw;">● Autorități Legale și Guvernamentale: În cazul
                  unor cerințe legale sau pentru protecția drepturilor legale.</p>
                <p
                    style="text-indent: 10vw;">● Tranzacții Comerciale: În cazul unei fuziuni,
                  vânzări sau alte tranzacții comerciale.</p>
                <p style="text-indent: 10vw;">
                  ● Alte Părți cu Acordul Dumneavoastră: Alte situații în care aveți acordul
                  explicit.</p>
                <p><b>4.3. Responsabilitatea pentru Confidențialitatea
                  Datelor</b></p>
                <p style="text-indent: 5vw;">EdNeos este responsabil pentru
                  confidențialitatea datelor furnizate către furnizorii de servicii și parteneri,
                  asigurându-se că aceștia respectă standardele noastre de protecție și securitate
                  a datelor.</p>
                <p><b>5. Securitatea Datelor la Edneos</b>
                </p>
                <p style="text-indent: 5vw;">EdNeos implementează un program
                  cuprinzător de securitate menit să protejeze securitatea, confidențialitatea și
                  integritatea informațiilor personale. Implementăm măsuri tehnice și organizatorice
                  adecvate pentru a proteja datele personale împotriva pierderii, utilizării
                  greșite,
                  accesului neautorizat, dezvăluirii, modificării și distrugerii. Aceste măsuri
                  includ
                  criptarea datelor, firewall-uri, controlul accesului la date și la facilități și
                  conformitatea cu standardele industriale relevante, cum ar fi PCI pentru
                  tranzacții
                  cu cardul de credit.</p>
                <p><b>5.1. Transferuri Internaționale de Date
                </b></p>
                <p style="text-indent: 5vw;">Datele colectate de Edneos pot fi transferate,
                  procesate și stocate în afara țării de reședință a utilizatorilor, în țările unde
                  operăm sau unde se află furnizorii noștri de servicii. Ne asigurăm că orice astfel
                  de transfer respectă legislația aplicabilă și se face pe baza unor temeiuri
                  juridice
                  adecvate, inclusiv clauze contractuale standard aprobate de Comisia Europeană.</p>
                <p><b>5.2. Păstrarea Informațiilor Personale</b></p>
                <p
                    style="text-indent: 5vw;">Edneos nu păstrează informațiile personale mai mult
                  decât
                  este necesar pentru scopurile pentru care au fost colectate. Durata de păstrare
                  depinde de nevoia legală sau contractuală, necesitatea datelor pentru furnizarea
                  serviciilor noastre și așteptările rezonabile ale utilizatorilor. Atunci când nu
                  mai
                  este necesară păstrarea datelor personale, acestea sunt șterse sau anonimizate
                  conform legislației aplicabile.</p>
                <p><b>5.3. Utilizatorii din
                  Anumite Jurisdicții</b></p>
                <p style="text-indent: 5vw;">Pentru utilizatorii din
                  California, Colorado, Connecticut, Utah și Virginia, EdNeos oferă detalii
                  suplimentare privind colectarea, utilizarea și dezvăluirea informațiilor
                  personale,
                  conform legilor specifice ale acestor state. Aceasta include informații despre
                  categoriile de date personale colectate, procesate și dezvăluite, precum și
                  drepturile specifice ale consumatorilor din aceste jurisdicții.</p>
                <p><b>5.4. Categorii de Informații Personale Colectate și Procesate de Edneos</b>
                </p>
                <p style="text-indent: 5vw;">● Identificatori Personali</p>
                <p
                    style="text-indent: 10vw;">Exemple: Nume, adrese de e-mail, cookie-uri.</p>
                <p style="text-indent: 10vw;">Scopurile de Prelucrare: Furnizarea serviciilor
                  Edneos, permiterea utilizării funcțiilor, comunicare, suport și îmbunătățirea
                  serviciilor, tranzacții și plăți, prevenirea abuzului, respectarea obligațiilor
                  legale, optimizarea și analiza datelor, marketing, participarea la tombole și
                  promoții, date agregate și anonimizate.</p>
                <p style="text-indent: 5vw;">
                  ● Informații Personale (definite conform legii privind evidențele clienților
                  din California)</p>
                <p style="text-indent: 10vw;">Exemple: Informații de contact,
                  informații de plată.</p>
                <p style="text-indent: 10vw;">Scopurile de Prelucrare:
                  Aceleași ca la identificatori personali.</p>
                <p style="text-indent: 5vw;">
                  ● Informații de Clasă Protejate</p>
                <p style="text-indent: 10vw;">Exemple: Vârstă.
                </p>
                <p style="text-indent: 10vw;">Scopurile de Prelucrare: Aceleași ca la
                  identificatori personali.</p>
                <p style="text-indent: 5vw;">● Informații Comerciale
                </p>
                <p style="text-indent: 10vw;">Exemple: Informații despre tranzacții și istoricul
                  achizițiilor.</p>
                <p style="text-indent: 10vw;">Scopurile de Prelucrare: Aceleași
                  ca la identificatori personali.</p>
                <p style="text-indent: 5vw;">● Informații despre
                  Activitatea pe Internet sau în Rețea</p>
                <p style="text-indent: 10vw;">Exemple:
                  Istoric de navigare, istoric de căutări, interacțiuni cu site-ul Edneos.</p>
                <p style="text-indent: 10vw;">Scopurile de Prelucrare: Aceleași ca la identificatori
                  personali.</p>
                <p style="text-indent: 5vw;">● Datele de Geolocalizare</p>
                <p style="text-indent: 10vw;">Exemple: Informații la nivel de oraș, derivate din
                  adresa IP.</p>
                <p style="text-indent: 10vw;">Scopurile de Prelucrare: Aceleași
                  ca la identificatori personali.</p>
                <p style="text-indent: 5vw;">● Informații Audio,
                  Electronice, Vizuale</p>
                <p style="text-indent: 10vw;">Exemple: Fotografii încărcate
                  de utilizatori.</p>
                <p style="text-indent: 10vw;">Scopurile de Prelucrare: Furnizarea
                  serviciilor, suportul și îmbunătățirea serviciilor, prevenirea abuzului,
                  respectarea
                  obligațiilor legale, optimizarea serviciului, analiza datelor.</p>
                <p>
                  <b>5.5. Dezvăluirea Informațiilor Către Terți</b></p>
                <p style="text-indent: 5vw;">
                  EdNeos poate dezvălui aceste categorii de informații personale către:</p>
                <p
                    style="text-indent: 10vw;">● Afiliați și prestatori de servicii pentru scopurile
                  operaționale.</p>
                <p style="text-indent: 10vw;">● Instituții publice pentru
                  conformitate legală și protecția drepturilor.</p>
                <p style="text-indent: 10vw;">
                  ● Furnizori de rețele sociale pentru promovarea serviciilor.</p>
                <p
                    style="text-indent: 10vw;">● Potențiali cumpărători în cazul tranzacțiilor
                  comerciale.
                </p>
                <p style="text-indent: 10vw;">● Alte terțe părți cu acordul expres al
                  utilizatorului.</p>
                <p><b>6. Drepturi și Cereri
                  în cadrul EdNeos</b></p>
                <p style="text-indent: 5vw;">Exercitarea
                  Drepturilor Dumneavoastră</p>
                <p><b>6.1 Conform legislației
                  aplicabile, aveți dreptul de a face următoarele solicitări la Edneos:</b></p>
                <p style="text-indent: 5vw;">● Acces la Informații Personale: Aflați dacă
                  procesăm informațiile dvs. personale și accesați aceste informații.</p>
                <p
                    style="text-indent: 5vw;">● Corectarea Inexactităților: Rectificați
                  inexactitățile din informațiile dvs. personale.</p><span
                  style="display: inline-block; text-indent: 5vw;">● Ștergerea Datelor:&nbsp;
              </span>
                <a target="_blank"
                   href="https://edneos.com/delete-account-form"><span style="color: blue;
               text-decoration: underline;">Solicitați ștergerea informațiilor personale.
              </span></a>
                <p style="text-indent: 5vw;">● Portabilitatea Datelor: Primiți o
                  copie a informațiilor personale într-un format portabil.</p>
                <p><b>6.2. Cereri Specifice pentru Rezidenți în Anumite Jurisdicții:</b></p>
                <p style="text-indent: 5vw;">● Solicitați categoriile de informații personale
                  colectate, scopul colectării și categoriile de terțe părți cărora le-am
                  dezvăluit aceste informații.</p>
                <p><b>6.3. Non-Discriminare:
                </b></p>
                <p style="text-indent: 5vw;">Aveți dreptul de a nu fi discriminat
                  pentru exercitarea drepturilor de confidențialitate.</p>
                <p>
                  <b>6.4. Procesul de Trimitere a Cererilor</b></p>
                <p style="text-indent: 5vw;">
                  ● Contactați-ne la hello@edneos.com pentru a face o cerere.</p>
                <p
                    style="text-indent: 5vw;">● Vom verifica și răspunde solicitărilor
                  dumneavoastră conform legislației aplicabile.</p>
                <p style="text-indent: 5vw;">
                  ● Pentru verificarea identității, putem solicita informații suplimentare, cum
                  ar fi adresa de e-mail înregistrată sau activitatea recentă de conectare.</p>
                <p><b>6.5. Rolul Edneos în Prelucrarea Datelor</b></p>
                <p
                    style="text-indent: 5vw;">● Edneos acționează ca furnizor de servicii sau
                  procesor, colectând informații personale în numele utilizatorilor noștri.</p>
                <p style="text-indent: 5vw;">● În cazul unor solicitări de acces, corectare
                  sau ștergere a informațiilor personale, vă rugăm să contactați entitatea
                  cu care aveți o relație directă.</p>
                <p><b>6.6. Vânzarea
                  Informațiilor Personale</b></p>
                <p style="text-indent: 5vw;">● Edneos nu
                  vinde informații personale (inclusiv informații personale sensibile) și
                  nu participă la publicitate comportamentală sau direcționată încrucișată.
                </p>
                <p><b>6.7. Procesul de Apel</b></p>
                <p
                    style="text-indent: 5vw;">● Solicitările făcute de agenți autorizați vor
                  fi procesate conform legislației aplicabile. Este posibil să solicităm
                  confirmarea identității agentului și a permisiunii acordate de dvs.</p>
                <p><b>6.8. Utilizarea Informațiilor De-Identificate</b></p>
                <p style="text-indent: 5vw;">● Edneos menține și utilizează informațiile
                  de-identificate numai într-un mod de-identificat și nu va încerca să
                  reidentifice informațiile.</p>
                <p><b>6.9. Nu Urmăriți (Do Not
                  Track)</b></p>
                <p style="text-indent: 5vw;">● Edneos respectă setările "Nu
                  urmăriți" din browserul dvs. și nu monitorizează sau urmărește nicio solicitare
                  de browser Do Not Track, conform standardelor stabilite în această Politică de
                  confidențialitate.</p>
                <p><b>7. Modificări
                  ale Politicii de Confidențialitate</b></p>
                <p style="text-indent: 5vw;"
                >Edneos își rezervă dreptul de a modifica această Politică de confidențialitate.
                  Orice modificări semnificative vor fi comunicate în avans prin e-mail sau prin
                  alte mijloace de notificare. Continuarea utilizării serviciilor Edneos după astfel
                  de modificări va constitui acceptul dumneavoastră al acestor schimbări.</p>
                <p><b>8. Colectarea Informațiilor Personale de la Copii
                </b></p>
                <p style="text-indent: 5vw;">EdNeos nu colectează informații
                  personale de la copii în scopuri comerciale. Aplicația este destinată mai mult
                  elevilor ți studenților de la 14 ani. Este dificil de utilizat pentru cei mai mici
                  de această vârstă. În cazul în care un copil își creează un cont, colectăm adresa
                  de
                  e-mail exclusiv pentru resetarea parolei și nu pentru alte scopuri. De asemenea,
                  putem colecta un nume de utilizator și o parolă pentru scopuri de conectare.
                  Respectăm
                  toate cerințele legale referitoare la colectarea informațiilor personale de la
                  copii
                  și oferim părinților sau tutorilor posibilitatea de a accesa și de a gestiona
                  aceste
                  informații.</p>
                <p><b>9. Întrebări și
                  Preocupări
                </b></p>
                <p style="text-indent: 5vw;">Dacă aveți întrebări sau nelămuriri
                  legate de practicile noastre de confidențialitate, vă rugăm să ne contactați la
                  hello@edneos.com . Suntem dedicați la abordarea oricăror probleme legate de
                  confidențialitate și protecția datelor.</p>
                <p><b>Anexa A – Definiții</b></p>
                <p style="text-indent: 5vw;">
                  ● "Afiliat": Entitate care controlează, este controlată de, sau se află sub
                  control
                  comun cu Edneos.</p>
                <p style="text-indent: 5vw;">● "Aplicație": Software-ul/
                  aplicațiile mobile ale Edneos.</p>
                <p style="text-indent: 5vw;">● "Copil" sau
                  "Copii": Copii sub 13 ani în S.U.A. și sub vârsta de 16 ani în alte jurisdicții.
                </p>
                <p style="text-indent: 5vw;">
                  ● "Informații personale": Orice informație care identifică sau poate fi utilizată
                  pentru a identifica o persoană.</p>
                <p style="text-indent: 5vw;">● "Servicii":
                  Serviciile oferite de Edneos, inclusiv orice software disponibil pe platforma
                  noastră.</p>
                <p style="text-indent: 5vw;">● "Utilizator": Orice persoană sau
                  entitate care utilizează serviciile Edneos.</p></div>
            </b-col>

          </b-row>
        </div>
        <div class="content" v-if="page === 'privacy' && language === 'en'">
          <b-row>
            <b-col>

              <div class="mb-5">
                <div style="display: flex; vertical-align: center">
                  <div v-on:click="page = 'view_profile'" style="
                    display: inline-flex;cursor: pointer; vertical-align: center;
                    align-items: center;
">
                    <unicon name="arrow-left" width="30" height="30" fill="#333333"
                            style="margin-right: 8px"/>
                    <div class="d-inline-flex">
                      <div class="profile-image">
                        <img :src="image"/>
                      </div>
                      <div class="title-2-bold-20 basic-1 m-0" style="align-items: center;
                    vertical-align: center; display: inline-flex">
                        {{ name }}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="Privacy_container__TkhHO"><p><b>Edneos Privacy Policy</b></p>
                <p><b>1. About This Privacy Policy</b></p>
                <p style="text-indent: 5vw;">Thank you for choosing the EdNeos mobile application
                  ("the Application") and/or the EdNeos web platform ("the Web Platform"). This
                  Privacy Policy explains how we collect, use, and protect your personal
                  information when you use the Application/Web Platform. By using the
                  Application/Web Platform, you agree to the practices described in this Privacy
                  Policy. If you do not agree with the practices described below, please do not
                  use the Application/Web Platform.</p>
                <p style="text-indent: 5vw;">Edneos is a
                  global educational technology group offering an interactive learning platform.
                  We focus on developing engaging learning tools for both the educational
                  sector and individual users.
                </p>
                <p><b>1.1. Personal Information Processed by Edneos</b></p>
                <p style="text-indent: 5vw;">We collect and process minimal personal
                  information depending on how you interact with Edneos, your account settings,
                  and the products you use. This information includes:</p>
                <p
                    style="text-indent: 10vw;">● Information Provided by You: Name, email address.
                </p>
                <p style="text-indent: 10vw;">● Communication Data: Information shared through
                  interaction with Edneos services, such as comments on the platform.</p>
                <p><b>2. Use of Personal Information</b></p>
                <p
                    style="text-indent: 5vw;">We use the collected information to provide and
                  improve Edneos services, to communicate with you, and to respond to support
                  requests.</p>
                <p style="text-indent: 5vw;">Edneos implements security measures
                  to protect personal data against unauthorized access and illegal use.</p>
                <p
                    style="text-indent: 5vw;">You have the right to access, correct, or delete
                  the personal information we hold about you and to restrict its processing
                  in the Settings menu.</p>
                <p><b>3.
                  Information We Collect Automatically</b></p>
                <p
                    style="text-indent: 5vw;">At EdNeos, we respect data protection legislation
                  and consider certain information as personal according to applicable laws. We
                  automatically collect various types of data during your use of our service,
                  including:</p>
                <p><b>3.1. Device and Browser Information:</b></p>
                <p style="text-indent: 5vw;">● The type of computer (Windows or Macintosh).</p>
                <p style="text-indent: 5vw;">● Screen resolution.</p>
                <p style="text-indent: 5vw;">
                  ● Name and version of the operating system.</p>
                <p style="text-indent: 5vw;">
                  ● Device manufacturer and model.</p>
                <p style="text-indent: 5vw;">● Language.
                </p>
                <p style="text-indent: 5vw;">● Type and version of the internet browser.</p>
                <p style="text-indent: 5vw;">● Version of the Edneos application used.</p>
                <p><b>3.2. Usage Data:</b></p>
                <p style="text-indent: 5vw;">
                  ● Browsing activities and interactions with the service (such as sections used).
                </p>
                <p style="text-indent: 5vw;">● Information regarding service performance,
                  including aspects related to email delivery and other communications.</p>
                <p
                    style="text-indent: 5vw;">● Data on the frequency of application use and
                  other performance data.</p>
                <p><b>3.3. Log Data:</b></p>
                <p
                    style="text-indent: 5vw;">● Records of accesses on our web servers, including
                  originating IP addresses.</p>
                <p style="text-indent: 5vw;">● Activity in
                  services,
                  such as timestamps, interactions with application features, and other actions
                  taken.</p>
                <p><b>3.4. Cookies and Tracking Technologies:</b></p>
                <p style="text-indent: 5vw;">● We collect information through cookies and
                  similar technologies to understand how our service is used.</p>
                <p
                    style="text-indent: 5vw;">● Pixel tags are used to track user actions
                  and measure the effectiveness of marketing campaigns.</p>
                <p><b>4. Your Data Protection Rights with EdNeos
                </b></p>
                <p><b>4.1. Know Your Rights</b></p>
                <p
                    style="text-indent: 5vw;">Depending on your jurisdiction, you may have
                  the following data protection rights:</p>
                <p style="text-indent: 10vw;">
                  ● Access, correct, update, transfer, delete, restrict, or object to
                  Edneos' processing of your personal information.</p>
                <p
                    style="text-indent: 10vw;">● Withdraw consent for processing based
                  on consent, without affecting the lawfulness of processing carried
                  out before withdrawal.</p>
                <p><b>Exercising Your Rights
                </b></p>
                <p style="text-indent: 5vw;">To exercise your rights, you can
                  contact us at hello@edneos.com. We respond to requests to exercise
                  data protection rights in accordance with applicable law. We may ask
                  you to verify your identity to efficiently process your request.</p>
                <p><b>4.2. Sharing Personal Information</b></p>
                <p
                    style="text-indent: 5vw;">EdNeos does not share personal information
                  with third parties, except in the limited cases described below:</p>
                <p style="text-indent: 10vw;">● Affiliates and Service Providers: May
                  be shared with affiliated companies or service providers who perform services on
                  our behalf, such as web hosting, technical support, payment processing, and
                  other similar services.</p>
                <p style="text-indent: 10vw;">● Social Network Partners: May be used in
                  cooperation with social networks to promote EdNeos services.</p>
                <p style="text-indent: 10vw;">● Legal and Government Authorities: In case of legal
                  requirements or to protect legal rights.</p>
                <p style="text-indent: 10vw;">● Business Transactions: In case of a merger, sale,
                  or other business transactions</p>
                <p style="text-indent: 10vw;">● Other Parties with Your Consent: Other situations
                  where you have given explicit consent.</p>
                <p><b>4.3. Responsibility for Data Privacy</b></p>
                <p style="text-indent: 5vw;">EdNeos is responsible for the privacy of data
                  provided to service providers and partners, ensuring that they adhere to our
                  data protection and security standards.</p>
                <p><b>5. Data Security at EdNeos</b></p>
                <p style="text-indent: 5vw;">EdNeos implements a comprehensive security program
                  designed to protect the security, privacy, and integrity of personal
                  information. We implement appropriate technical and organizational measures to
                  protect personal data against loss, misuse, unauthorized access, disclosure,
                  alteration, and destruction. These measures include data encryption, firewalls,
                  access control to data and facilities, and compliance with relevant industry
                  standards such as PCI for credit card transactions.</p>
                <p><b>5.1. International Data Transfers</b></p>
                <p style="text-indent: 5vw;">Data collected by Edneos may be transferred,
                  processed, and stored outside the country of residence of users, in countries
                  where we operate or where our service providers are located. We ensure that any
                  such transfer complies with applicable law and is based on adequate legal
                  grounds, including standard contractual clauses approved by the European
                  Commission.</p>
                <p><b>5.2. Retention of Personal Information</b></p>
                <p style="text-indent: 5vw;">Edneos does not retain personal information longer
                  than necessary for the purposes for which it was collected. The retention
                  duration depends on legal or contractual needs, the necessity of the data for
                  providing our services, and reasonable user expectations. When personal data is
                  no longer necessary, it is deleted or anonymized according to applicable
                  law.</p>
                <p><b>5.3. Users in Certain Jurisdictions</b></p>
                <p style="text-indent: 5vw;">For users in California, Colorado, Connecticut, Utah,
                  and Virginia, EdNeos provides additional details regarding the collection, use,
                  and disclosure of personal information, in accordance with the specific laws of
                  these states. This includes information about the categories of personal data
                  collected, processed, and disclosed, as well as the specific rights of consumers
                  in these jurisdictions.</p>
                <p><b>5.4. Categories of Personal Information Collected and Processed by
                  Edneos</b></p>
                <p style="text-indent: 5vw;">● Personal Identifiers</p>
                <p style="text-indent: 10vw;">Examples: Names, email addresses, cookies.</p>
                <p style="text-indent: 10vw;">Purposes of Processing: Providing Edneos services,
                  enabling use of features, communication, support and service improvement,
                  transactions and payments, abuse prevention, legal obligations compliance, data
                  optimization and analysis, marketing, participation in sweepstakes and
                  promotions, aggregated and anonymized data.</p>
                <p style="text-indent: 5vw;">● Personal Information (as defined under California's
                  customer records law)</p>
                <p style="text-indent: 10vw;">Examples: Contact information, payment
                  information.</p>
                <p style="text-indent: 10vw;">Purposes of Processing: Same as for personal
                  identifiers.</p>
                <p style="text-indent: 5vw;">● Protected Class Information</p>
                <p style="text-indent: 10vw;">Examples: Age.</p>
                <p style="text-indent: 10vw;">Purposes of Processing: Same as for personal
                  identifiers.</p>
                <p style="text-indent: 5vw;">● Commercial Information</p>
                <p style="text-indent: 10vw;">Examples: Transaction information and purchase
                  history.</p>
                <p style="text-indent: 10vw;">Purposes of Processing: Same as for personal
                  identifiers.</p>
                <p style="text-indent: 5vw;">● Internet or Network Activity Information</p>
                <p style="text-indent: 10vw;">Examples: Browsing history, search history,
                  interactions with Edneos website.</p>
                <p style="text-indent: 10vw;">Purposes of Processing: Same as for personal
                  identifiers.</p>
                <p style="text-indent: 5vw;">● Geolocation Data</p>
                <p style="text-indent: 10vw;">Examples: City-level information derived from IP
                  address.</p>
                <p style="text-indent: 10vw;">Purposes of Processing: Same as for personal
                  identifiers.</p>
                <p style="text-indent: 5vw;">● Audio, Electronic, Visual Information</p>
                <p style="text-indent: 10vw;">Examples: Photos uploaded by users.</p>
                <p style="text-indent: 10vw;">Purposes of Processing: Providing services, support
                  and service improvement, abuse prevention, legal obligations compliance, service
                  optimization, data analysis.</p>
                <p><b>5.5. Disclosure of Information to Third Parties</b></p>
                <p style="text-indent: 5vw;">EdNeos may disclose these categories of personal
                  information to:</p>
                <p style="text-indent: 10vw;">● Affiliates and service providers for operational
                  purposes.</p>
                <p style="text-indent: 10vw;">● Public institutions for legal compliance and
                  rights protection.</p>
                <p style="text-indent: 10vw;">● Social network providers for service
                  promotion.</p>
                <p style="text-indent: 10vw;">● Potential buyers in business transactions.</p>
                <p style="text-indent: 10vw;">● Other third parties with the express consent of
                  the user.</p>
                <p><b>6. Rights and Requests within EdNeos</b></p>
                <p style="text-indent: 5vw;">Exercising Your Rights</p>
                <p><b>6.1 In accordance with applicable law, you have the right to make the
                  following requests to Edneos:</b></p>
                <p style="text-indent: 5vw;">● Access Personal Information: Find out if we are
                  processing your personal information and access these details.</p>
                <p style="text-indent: 5vw;">● Correct Inaccuracies: Rectify inaccuracies in your
                  personal information.</p><span style="display: inline-block; text-indent: 5vw;">
                    ● Delete Data:&nbsp;</span><a target="_blank"
                                                  href="https://edneos.com/delete-account-form"><span
                    style="color: blue; text-decoration: underline;">Request the deletion of your
                    personal information.</span></a>
                <p style="text-indent: 5vw;">● Data Portability: Receive a copy of your personal
                  information in a portable format.</p>
                <p><b>6.2. Specific Requests for Residents in Certain Jurisdictions:</b></p>
                <p style="text-indent: 5vw;">● Request the categories of personal information
                  collected, the purpose of collection, and the categories of third parties to
                  whom we have disclosed this information.</p>
                <p><b>6.3. Non-Discrimination:</b></p>
                <p style="text-indent: 5vw;">You have the right not to be discriminated against
                  for exercising your privacy rights.</p>
                <p><b>6.4. The Process of Submitting Requests</b></p>
                <p style="text-indent: 5vw;">● Contact us at hello@edneos.com to make a
                  request.</p>
                <p style="text-indent: 5vw;">● We will verify and respond to your requests in
                  accordance with applicable law.</p>
                <p style="text-indent: 5vw;">● For identity verification, we may request
                  additional information such as your registered email address or recent login
                  activity.</p>
                <p><b>6.5. Edneos' Role in Data Processing</b></p>
                <p style="text-indent: 5vw;">● Edneos acts as a service provider or processor,
                  collecting personal information on behalf of our users.</p>
                <p style="text-indent: 5vw;">● For requests regarding access, correction, or
                  deletion of personal information, please contact the entity with which you have
                  a direct relationship.</p>
                <p><b>6.6. Sale of Personal Information</b></p>
                <p style="text-indent: 5vw;">● Edneos does not sell personal information
                  (including sensitive personal information) and does not engage in cross-context
                  behavioral advertising.</p>
                <p><b>6.7. Appeal Process</b></p>
                <p style="text-indent: 5vw;">● Requests made by authorized agents will be
                  processed in accordance with applicable law. We may request confirmation of the
                  agent's identity and your permission granted to them.</p>
                <p><b>6.8. Use of De-Identified Information</b></p>
                <p style="text-indent: 5vw;">● Edneos maintains and uses de-identified information
                  only in a de-identified manner and will not attempt to re-identify the
                  information.</p>
                <p><b>6.9. Do Not Track (DNT)</b></p>
                <p style="text-indent: 5vw;">● Edneos respects "Do Not Track" settings in your
                  browser and does not monitor or follow any Do Not Track browser requests, as per
                  the standards set in this Privacy Policy.</p>
                <p><b>7. Changes to the Privacy Policy</b></p>
                <p style="text-indent: 5vw;">Edneos reserves the right to modify this Privacy
                  Policy. Any significant changes will be communicated in advance via email or
                  other notification means. Your continued use of Edneos services after such
                  changes will constitute your acceptance of these changes.</p>
                <p><b>8. Collection of Personal Information from Children</b></p>
                <p style="text-indent: 5vw;">EdNeos does not collect personal information from
                  children for commercial purposes. The application is more suitable for students
                  aged 14 and older and is challenging to use for younger children. If a child
                  creates an account, we collect the email address exclusively for password
                  resetting and not for other purposes. We may also collect a username and
                  password for login purposes. We comply with all legal requirements regarding the
                  collection of personal information from children and provide parents or
                  guardians the ability to access and manage this information.</p>
                <p><b>9. Questions and Concerns</b></p>
                <p style="text-indent: 5vw;">If you have any questions or concerns about our
                  privacy practices, please contact us at hello@edneos.com. We are committed to
                  addressing any issues related to privacy and data protection.</p>
                <p><b>Appendix A – Definitions</b></p>
                <p style="text-indent: 5vw;">● "Affiliate": An entity that controls, is controlled
                  by, or is under common control with Edneos.</p>
                <p style="text-indent: 5vw;">● "Application": The mobile software/applications of
                  Edneos.</p>
                <p style="text-indent: 5vw;">● "Child" or "Children": Children under 13 years of
                  age in the U.S. and under 16 years of age in other jurisdictions.</p>
                <p style="text-indent: 5vw;">● "Personal Information": Any information that
                  identifies or can be used to identify a person.</p>
                <p style="text-indent: 5vw;">● "Services": The services offered by Edneos,
                  including any software available on our platform.</p>
                <p style="text-indent: 5vw;">● "User": Any individual or entity that uses Edneos
                  services.</p></div>
            </b-col>
          </b-row>
        </div>
        <div class="content" v-if="page === 'terms' && language === 'ro'">
          <b-row>
            <b-col>
              <div class="mb-5">
                <div style="display: flex; vertical-align: center">
                  <div v-on:click="page = 'view_profile'" style="
                    display: inline-flex;cursor: pointer; vertical-align: center;
                    align-items: center;
">
                    <unicon name="arrow-left" width="30" height="30" fill="#333333"
                            style="margin-right: 8px"/>
                    <div class="d-inline-flex">
                      <div class="profile-image">
                        <img :src="image"/>
                      </div>
                      <div class="title-2-bold-20 basic-1 m-0" style="align-items: center;
                    vertical-align: center; display: inline-flex">
                        {{ name }}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="Terms_container__ozi61"><p>Data efectivă: 01.12.2023</p>
                <p><b>Bine ați venit la EdNeos!</b></p>
                <p>Introducere:</p>
                <p style="text-indent: 5vw;">EdNeos este un partener educațional AI inovator,
                  conceput să vă sprijine în călătoria dumneavoastră de învățare. Fie că sunteți
                  elev sau student, EdNeos oferă un mediu interactiv de învățare, utilizând
                  ultimele descoperiri în neuroștiință și tehnologie pentru a vă ajuta să
                  asimilați și să rețineți cunoștințele mai eficient. Prin utilizarea EdNeos, veți
                  avea acces la resurse educaționale personalizate, instrumente de învățare
                  adaptative și un suport constant pentru a vă îmbunătăți performanțele
                  academice.</p>
                <p>Ce urmează:</p>
                <p style="text-indent: 5vw;">Înainte de a începe să utilizați EdNeos, vă rugăm să
                  citiți cu atenție termenii și condițiile noastre. Acestea vă vor ghida în
                  utilizarea responsabilă și eficientă a platformei noastre și vă vor informa
                  despre drepturile și responsabilitățile dvs. ca utilizator.</p>
                <p><b>1. Aspecte generale:</b></p>
                <p><b>1.1. Acceptarea Termenilor:</b></p>
                <p style="text-indent: 5vw;">Prin utilizarea serviciilor și platformei EdNeos,
                  acceptați acești termeni, inclusiv politica noastră de utilizare acceptabilă și
                  orice planuri de servicii. Acești termeni creează un acord legal între dvs. și
                  EdNeos.</p>
                <p><b>1. 2. Condiții de Utilizare:</b></p>
                <p style="text-indent: 5vw;">Serviciile EdNeos sunt disponibile sub anumite
                  condiții. Dacă nu sunteți de acord cu aceste condiții, nu trebuie să folosiți
                  serviciile EdNeos.</p>
                <p><b>1.3. Politica de Confidențialitate și Cookie-uri:</b></p>
                <p style="text-indent: 5vw;">Informațiile dvs. personale sunt tratate conform
                  Politicii noastre de Confidențialitate și Politicii de Cookie-uri. Vă rugăm să
                  le citiți pentru a înțelege cum sunt gestionate datele dvs.</p>
                <p><b>1.4. Utilizare Responsabilă:</b></p>
                <p style="text-indent: 5vw;">● Serviciile EdNeos trebuie folosite numai pentru
                  scopurile permise și în conformitate cu legile aplicabile.</p>
                <p style="text-indent: 5vw;">● Sunteți responsabil pentru securitatea contului
                  dvs. și pentru orice activitate sub acesta.</p>
                <p style="text-indent: 5vw;">● Trebuie să raportați imediat orice utilizare
                  suspectă sau neautorizată a contului dvs.</p>
                <p style="text-indent: 5vw;">● Garantați că toate informațiile pe care le
                  furnizați sunt corecte și actuale.</p>
                <p><b>1.5. Responsabilitatea Utilizatorului:</b></p>
                <p style="text-indent: 5vw;">EdNeos nu este responsabil pentru acuratețea
                  informațiilor furnizate de utilizatori sau pentru verificarea identității
                  acestora.</p>
                <p><b>1.6. Acțiuni Legale:</b></p>
                <p style="text-indent: 5vw;">EdNeos își rezervă dreptul de a întreprinde acțiuni
                  legale împotriva celor care denaturează informații sau își falsifică
                  identitatea.</p>
                <p><b>1.7 Proprietate intelectuală:</b></p>
                <p style="text-indent: 5vw;">Toate drepturile de proprietate intelectuală asupra
                  aplicației și conținutului său, inclusiv, dar fără a se limita la drepturile de
                  autor, mărci comerciale și secrete comerciale, sunt deținute și licențiate de
                  către EdNeos. Sunteți de acord să nu copiați, modificați, distribuiți, vindeți
                  sau exploatați nicio parte a aplicației fără acordul nostru scris în
                  prealabil.</p>
                <p><b>2. Planuri de Servicii Disponibile la EdNeos</b></p>
                <p style="text-indent: 5vw;">EdNeos oferă o gamă variată de planuri de servicii
                  pentru a răspunde nevoilor diverse ale utilizatorilor noștri. Informații
                  detaliate despre aceste planuri pot fi găsite în aplicație la rubirca abonamente
                  din setări sau pe site-ul nostru web . Planurile noastre sunt clasificate
                  astfel:</p>
                <p><b>2.1. Planuri de Servicii Gratuite:</b></p>
                <p style="text-indent: 5vw;">Aceste planuri oferă acces la funcții de bază ale
                  platformei EdNeos și sunt destinate utilizării non-comerciale. Sunt ideale
                  pentru elevi și studenți care doresc să exploreze funcționalitățile de bază ale
                  platformei.</p>
                <p><b>2.2. Planuri de Servicii Plătite:</b></p>
                <p style="text-indent: 5vw;">Planurile plătite permit accesul la funcții avansate
                  și resurse suplimentare. Acestea sunt potrivite pentru utilizare profesională și
                  comercială, inclusiv în medii educaționale, de formare și cercetare.
                  Caracteristicile și limitările fiecărui plan pot varia cu unele funcționalități
                  separate iar utilizatorul alege planul de servicii ( abonamentul Pro sau Premium
                  ) după preferințele și necesitățile personale.</p>
                <p><b>2.3. Gestionarea Planurilor și Abonamentelor:</b></p>
                <p style="text-indent: 5vw;">Puteți gestiona planurile de servicii și abonamentele
                  de conținut pentru achiziții efectuate prin platforme externe (ex. Google Play,
                  Apple App Store), gestionarea se face prin intermediul acestor platforme.</p>
                <p><b>2.4. Plăți, Anulări, și Upgrade-uri:</b></p>
                <p style="text-indent: 5vw;">● Planurile de servicii plătite și abonamentele de
                  conținut necesită plată în avans și sunt, în general, nerambursabile, cu
                  excepții conform legislației aplicabile.</p>
                <p style="text-indent: 5vw;">● La sfârșitul fiecărei perioade de abonament,
                  planurile și abonamentele se reînnoiesc automat, iar plata va fi efectuată
                  conform detaliilor de plată înregistrate.</p>
                <p style="text-indent: 5vw;">● Anulările pot fi efectuate înainte de sfârșitul
                  perioadei de abonament curente, cu efect la finalul perioadei respective.</p>
                <p><b>2.5. Flexibilitatea Planurilor de Servicii:</b></p>
                <p style="text-indent: 5vw;">● La EdNeos, puteți face upgrade sau downgrade la
                  orice moment al abonamentului dvs. la un alt plan de servicii plătite.</p>
                <p style="text-indent: 5vw;">● La upgrade, schimbarea va intra în vigoare imediat,
                  cu condiția plății taxei de upgrade.</p>
                <p style="text-indent: 5vw;">● La downgrade, modificarea va avea loc la sfârșitul
                  perioadei curente de abonament prin anularea unui abonament și procurarea celui
                  selectat.</p>
                <p><b>2.5. Modificări de Preț și Noi Planuri de Servicii:</b></p>
                <p style="text-indent: 5vw;">● EdNeos își rezervă dreptul de a modifica prețurile
                  pentru planurile de servicii și abonamentele de conținut. Orice schimbare de
                  preț va fi efectivă la începutul următoarei perioade de abonament.</p>
                <p style="text-indent: 5vw;">● Vă vom informa în mod corespunzător despre orice
                  modificare a prețului și aveți opțiunea de a anula serviciul în cazul în care nu
                  sunteți de acord cu noile tarife.</p>
                <p><b>2.6. Impozite:</b></p>
                <p style="text-indent: 5vw;">● Prețurile afișate pot exclude taxele aplicabile,
                  care vor fi calculate și adăugate la finalizarea tranzacției.</p>
                <p><b>3. Conținutul Utilizatorului:</b></p>
                <p><b>3.1. Acțiunile utilizatorului:</b></p>
                <p style="text-indent: 5vw;">Prin utilizarea aplicației, puteți trimite sau
                  încărca anumite tipuri de conținut, precum seturi de cărți cu întrebări și
                  răspunsuri, imagini sau alte materiale ("Conținutul utilizatorului"). D-voastră
                  aveți toate drepturile de proprietate asupra conținutului utilizatorului.</p>
                <p><b>3.2. Confidențialitatea și Utilizarea Conținutului:</b></p>
                <p style="text-indent: 5vw;">Conținutul pe care îl încărcați în EdNeos este
                  accesibil doar pentru dumneavoastră iar dacă decideți să-l furnizați unei
                  persoane, o faceți pe propria răspundere și poate fi utilizat de EdNeos în
                  scopul furnizării serviciilor noastre.</p>
                <p><b>3.3 Licența Acordată EdNeos:</b></p>
                <p style="text-indent: 5vw;">Prin utilizarea EdNeos, îi acordați o licență
                  limitată, mondială și neexclusivă pentru a accesa, utiliza și procesa conținutul
                  dvs. în scopuri rezonabile, cum ar fi furnizarea și îmbunătățirea serviciilor,
                  adresarea problemelor tehnice și conformitatea legală.</p>
                <p style="text-indent: 5vw;">EdNeos va elimina conținutul dvs. la rezilierea
                  contului, cu excepția conținutului public, care este gestionat conform politicii
                  noastre de confidențialitate.</p>
                <p><b>3.4. Responsabilitatea dvs. pentru Conținut:</b></p>
                <p style="text-indent: 5vw;">Sunteți responsabil pentru conținutul pe care îl
                  încărcați pe EdNeos și trebuie să dețineți toate drepturile necesare pentru
                  acesta.EdNeos nu susține nici corectitudinea, nici opiniile exprimate în
                  conținutul utilizatorului.</p>
                <p><b>3.5. Monitorizarea Conținutului Utilizatorului:</b></p>
                <p style="text-indent: 5vw;">EdNeos poate revizui, monitoriza și, la discreția
                  noastră, elimina orice conținut care încalcă termenii și condițiile noastre.</p>
                <p><b>3.6. Conținut Public de Utilizator:</b></p>
                <p style="text-indent: 5vw;">Dacă alegeți să faceți public conținutul dvs.,
                  acordați EdNeos o licență largă pentru a utiliza și distribui acest conținut în
                  diverse forme și medii.</p>
                <p><b>3.7. Creatori Verificați pe Platforma EdNeos:</b></p>
                <p style="text-indent: 5vw;">Platforma EdNeos permite creatorilor verificați să
                  ofere gratuit sau în dependență de tipul abonamentului, conținut educativ și
                  resurse către utilizatorii EdNeos. Vă vom cere acceptul de fiecare dată dacă
                  doriți să primiți acest conținut.</p>
                <p><b>4. Securitatea Contului la EdNeos</b></p>
                <p><b>4.1. Responsabilitatea Dvs. pentru Securitatea Contului:</b></p>
                <p style="text-indent: 5vw;">● Ca utilizator al EdNeos, aveți responsabilitatea
                  totală pentru accesul și controlul contului dvs. și al dispozitivelor folosite
                  pentru accesarea serviciilor.</p>
                <p style="text-indent: 5vw;">● Este esențial să mențineți securitatea
                  dispozitivelor dvs. și să nu dezvăluiți parolele sau detaliile de plată asociate
                  contului dvs.</p>
                <p style="text-indent: 5vw;">● Sunteți responsabil pentru actualizarea și
                  menținerea acurateței informațiilor legate de contul dvs.</p>
                <p style="text-indent: 5vw;">● În caz de utilizare neautorizată sau frauduloasă,
                  EdNeos își rezervă dreptul de a suspenda sau închide contul pentru protecția
                  tuturor părților implicate.</p>
                <p><b>4.2. Protejarea Datelor:</b></p>
                <p style="text-indent: 5vw;">EdNeos se angajează să protejeze și să proceseze
                  datele personale în conformitate cu legislația aplicabilă și politicile noastre
                  de confidențialitate. Edneos nu va cere alte date decât un nume scurt și emailul
                  dvs. Edneos nu va colecta alte informații, nici nu va cere să oferiți alte
                  informații.</p>
                <p><b>4.3. Termenul și Rezilierea:</b></p>
                <p style="text-indent: 5vw;">● Acordul dintre dvs. și EdNeos rămâne valabil atât
                  timp cât utilizați serviciile noastre.</p>
                <p style="text-indent: 5vw;">● EdNeos poate rezilia acordul sau poate suspenda
                  accesul la serviciile noastre în cazul utilizării neautorizate sau nerespectării
                  termenilor acordului.</p>
                <p style="text-indent: 5vw;">● În caz de reziliere, EdNeos nu va avea nicio
                  răspundere față de dvs. și nu va rambursa sumele deja plătite.</p>
                <p><b>4.4 Garanție și Declinarea Răspunderii:</b></p>
                <p style="text-indent: 5vw;">● EdNeos va depune eforturi rezonabile pentru ca
                  serviciile să funcționeze conform descrierilor disponibile pe site-ul nostru
                  web.</p>
                <p style="text-indent: 5vw;">● Nu garantăm că serviciile vor fi disponibile în mod
                  continuu sau fără întreruperi.</p>
                <p style="text-indent: 5vw;">● EdNeos nu este responsabil pentru problemele
                  cauzate de hardware, software sau componente externe serviciilor noastre.</p>
                <p><b>5. Limitarea Răspunderii EdNeos</b></p>
                <p><b>5.1. Extinderea Responsabilității:</b></p>
                <p style="text-indent: 5vw;">În conformitate cu legislația aplicabilă, EdNeos și
                  afiliații săi nu vor fi responsabili pentru daune speciale, indirecte,
                  incidentale, punitive, exemplare sau consecutive, inclusiv pentru pierderea de
                  date, afaceri sau profituri. Responsabilitatea totală a EdNeos în legătură cu
                  acest acord este limitată la suma plătită de dvs. către EdNeos în ultimele 12
                  luni.</p>
                <p><b>5.2. Excepții la Limitarea Răspunderii:</b></p>
                <p style="text-indent: 5vw;">● Această limitare nu exclude responsabilitatea
                  EdNeos pentru fraudă, declarații frauduloase, deces sau vătămare corporală
                  datorată neglijenței sau alte cazuri grave prevăzute de lege.</p>
                <p><b>5.3. Termeni Specifici Apple pentru Utilizatori iOS:</b></p>
                <p style="text-indent: 5vw;">● În cazul utilizării aplicației EdNeos pe
                  dispozitive iOS, aceasta este supusă termenilor și condițiilor Apple, inclusiv
                  drepturile limitate de utilizare a aplicației pe dispozitivele Apple.</p>
                <p><b>5.4. Termeni Specifici Apple pentru Utilizatori iOS ai Aplicației EdNeos</b>
                </p>
                <p style="text-indent: 5vw;">● Apple Inc. nu este parte la acești Termeni și nu
                  deține și nu este responsabilă pentru aplicația EdNeos.</p>
                <p style="text-indent: 5vw;">● Apple nu oferă garanții pentru aplicația EdNeos, cu
                  excepția posibilității de rambursare a prețului de achiziție, dacă este
                  cazul.</p>
                <p style="text-indent: 5vw;">● Apple nu este responsabilă pentru întreținerea sau
                  alte servicii de asistență pentru aplicația EdNeos și nu va fi răspunzătoare
                  pentru orice reclamații, pierderi, răspunderi, daune, costuri sau cheltuieli
                  legate de aplicație.</p>
                <p style="text-indent: 5vw;">● Orice întrebări sau plângeri legate de utilizarea
                  aplicației EdNeos, inclusiv cele referitoare la drepturile de proprietate
                  intelectuală, trebuie să fie îndreptate către EdNeos.</p>
                <p style="text-indent: 5vw;">● Licența acordată dvs. prin acești Termeni este
                  limitată la utilizarea aplicației EdNeos pe un dispozitiv Apple care rulează
                  iOS, deținut sau controlat de dvs., în conformitate cu Regulile de utilizare
                  stabilite în Termenii și Condițiile App Store Apple.</p>
                <p><b>6. Diverse:</b></p>
                <p style="text-indent: 5vw;">Acest acord reprezintă toți termenii conveniți între
                  dvs. și EdNeos și înlocuiește orice acorduri anterioare legate de subiectul
                  acestuia.</p>
                <p><b>6.1. Posibilități de comunicare:</b></p>
                <p style="text-indent: 5vw;">Echipa noastră tehnică lucrează zilnic pentru a
                  asigura cea mai bună precizie, completitudine și fiabilitate pentru toți
                  utilizatorii, și vă încurajăm să ne anunțați orice probleme pe care le-ați putea
                  întâmpina în timp ce utilizați aplicația sau platforma web EDNEOS la adresa</p>
                <p style="text-indent: 10vw;">hello@edneos.com.</p>
                <p><b>6.2. Modificări ale Termenilor:</b></p>
                <p style="text-indent: 5vw;">EdNeos își rezervă dreptul de a modifica termenii
                  acestui acord. Orice modificare va fi comunicată în mod corespunzător.</p>
                <p><b>6.3. Legea Aplicabilă și Jurisdicția:</b></p>
                <p style="text-indent: 5vw;">● Acest acord este guvernat de legile României. Orice
                  litigiu va fi soluționat în instanțele din România.</p>
                <p><b>7. Definiții EdNeos</b></p>
                <p style="text-indent: 5vw;">„Politica de Utilizare Acceptabilă” înseamnă politica
                  EdNeos de utilizare acceptabilă, care este actualizată periodic și disponibilă
                  pe site-ul nostru web.</p>
                <p style="text-indent: 5vw;">„Administrator” înseamnă persoana desemnată de
                  organizația dvs. pentru a gestiona conturile EdNeos ale acelei organizații, dacă
                  este cazul.</p>
                <p style="text-indent: 5vw;">„Acord” reprezintă acești Termeni, Politica de
                  Utilizare Acceptabilă, orice Plan de Servicii ales și, dacă este aplicabil,
                  orice Acord de Întreprindere.</p>
                <p style="text-indent: 5vw;">„Aplicație” înseamnă aplicațiile mobile și
                  software-ul EdNeos.</p>
                <p style="text-indent: 5vw;">„Apple” înseamnă Apple Inc., în contextul utilizării
                  aplicațiilor EdNeos pe dispozitive iOS.</p>
                <p style="text-indent: 5vw;">„Copii” înseamnă minorii sub vârsta de 13 ani din SUA
                  sau sub vârsta de 16 ani în alte jurisdicții.</p>
                <p style="text-indent: 5vw;">„Cod” se referă la coduri sau alte oferte
                  promoționale furnizate de EdNeos pentru acces la planuri de servicii plătite sau
                  abonamente de conținut.</p>
                <p style="text-indent: 5vw;">„Consumator” înseamnă o persoană fizică care
                  utilizează serviciile EdNeos fără scopuri comerciale.</p>
                <p style="text-indent: 5vw;">„Abonamente la Conținut” reprezintă abonamentele la
                  biblioteci de conținut educațional premium oferite de EdNeos, în plus față de
                  Planul de Servicii ales.</p>
                <p style="text-indent: 5vw;">„Acord de Întreprindere” se referă la un contract
                  separat între EdNeos și organizația dvs. asociată.</p>
                <p style="text-indent: 5vw;">„EdNeos” este denumirea companiei care furnizează
                  serviciile EdNeos.</p>
                <p style="text-indent: 5vw;">„EdNeos Brands” se referă la toate mărcile
                  comerciale, mărcile de servicii, nume comerciale, logo-uri și orice alte
                  caracteristici distinctive ale mărcii EdNeos.</p>
                <p style="text-indent: 5vw;">„Licență” înseamnă dreptul de utilizare acordat
                  conform secțiunii 3 a acestor Termeni.</p>
                <p style="text-indent: 5vw;">„Platformă” înseamnă site-urile web și aplicațiile
                  EdNeos unde serviciile sunt disponibile.</p>
                <p style="text-indent: 5vw;">„Conținut Public de Utilizator” se referă la
                  conținutul creat de utilizatori și făcut disponibil publicului larg prin
                  serviciile EdNeos.</p>
                <p style="text-indent: 5vw;">„Resurse” înseamnă informațiile, serviciile,
                  produsele și instrumentele oferite prin EdNeos.</p>
                <p style="text-indent: 5vw;">„Plan de Servicii” reprezintă diferitele planuri de
                  abonament pentru serviciile EdNeos.</p>
                <p style="text-indent: 5vw;">„Servicii” înseamnă serviciile educaționale și alte
                  servicii oferite prin EdNeos.</p>
                <p style="text-indent: 5vw;">„Perioada de Abonament” înseamnă durata pentru care
                  un Plan de Servicii sau un Abonament de Conținut este activ.</p>
                <p style="text-indent: 5vw;">„Termeni” se referă la termenii și condițiile
                  generale ale utilizării serviciilor EdNeos.</p>
                <p style="text-indent: 5vw;">„Aplicații ale Terților” înseamnă servicii și
                  software-uri oferite de terți, care sunt integrate în serviciile EdNeos.</p>
                <p style="text-indent: 5vw;">„Probă” reprezintă perioade de încercare pentru
                  Planurile de Servicii plătite sau Abonamentele de Conținut.</p>
                <p style="text-indent: 5vw;">„Conținutul Utilizatorului” înseamnă toate datele,
                  conținutul și informațiile încărcate de utilizatori în cadrul serviciilor
                  EdNeos.</p>
                <p style="text-indent: 5vw;">„Adresa URL de Vanitate” reprezintă o adresă URL
                  personalizată pentru un utilizator sau organizație în cadrul platformei
                  EdNeos.</p>
                <p style="text-indent: 5vw;">„Creator Verificat” se referă la un utilizator EdNeos
                  care a fost verificat conform standardelor noastre.</p>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="content" v-if="page === 'terms' && language === 'en'">
          <b-row>
            <b-col>

              <div class="mb-5">
                <div style="display: flex; vertical-align: center">
                  <div v-on:click="page = 'view_profile'" style="
                    display: inline-flex;cursor: pointer; vertical-align: center;
                    align-items: center;
">
                    <unicon name="arrow-left" width="30" height="30" fill="#333333"
                            style="margin-right: 8px"/>
                    <div class="d-inline-flex">
                      <div class="profile-image">
                        <img :src="image"/>
                      </div>
                      <div class="title-2-bold-20 basic-1 m-0" style="align-items: center;
                    vertical-align: center; display: inline-flex">
                        {{ name }}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="Terms_container__ozi61"><p>Effective Date: 01.12.2023</p>
                <p><b>Welcome to EdNeos!</b></p>
                <p>Introduction:</p>
                <p style="text-indent: 5vw;">EdNeos is an innovative AI educational partner,
                  designed to support your learning journey. Whether you are a student or a pupil,
                  EdNeos offers an interactive learning environment, using the latest findings in
                  neuroscience and technology to help you assimilate and retain knowledge more
                  efficiently. By using EdNeos, you will have access to personalized educational
                  resources, adaptive learning tools, and constant support to improve your
                  academic performance.</p>
                <p>What follows:</p>
                <p style="text-indent: 5vw;">Before starting to use EdNeos, please read our terms
                  and conditions carefully. They will guide you in the responsible and efficient
                  use of our platform and inform you about your rights and responsibilities as a
                  user.</p>
                <p><b>1. General aspects:</b></p>
                <p><b>1.1. Acceptance of Terms:</b></p>
                <p style="text-indent: 5vw;">By using EdNeos services and platform, you accept
                  these terms, including our acceptable use policy and any service plans. These
                  terms create a legal agreement between you and EdNeos.</p>
                <p><b>1.2. Conditions of Use:</b></p>
                <p style="text-indent: 5vw;">EdNeos services are available under certain
                  conditions. If you do not agree with these conditions, you should not use the
                  EdNeos services.</p>
                <p><b>1.3. Privacy and Cookie Policy:</b></p>
                <p style="text-indent: 5vw;">Your personal information is treated according to our
                  Privacy Policy and Cookie Policy. Please read them to understand how your data
                  is managed.</p>
                <p><b>1.4. Responsible Use:</b></p>
                <p style="text-indent: 5vw;">● EdNeos services should only be used for permitted
                  purposes and in accordance with applicable laws.</p>
                <p style="text-indent: 5vw;">● You are responsible for the security of your
                  account and any activity under it.</p>
                <p style="text-indent: 5vw;">● You must immediately report any suspicious or
                  unauthorized use of your account.</p>
                <p style="text-indent: 5vw;">● You guarantee that all information you provide is
                  accurate and current.</p>
                <p><b>1.5. User Responsibility:</b></p>
                <p style="text-indent: 5vw;">EdNeos is not responsible for the accuracy of
                  information provided by users or for verifying their identity.</p>
                <p><b>1.6. Legal Actions:</b></p>
                <p style="text-indent: 5vw;">EdNeos reserves the right to take legal action
                  against those who misrepresent information or falsify their identity.</p>
                <p><b>1.7 Intellectual Property:</b></p>
                <p style="text-indent: 5vw;">All intellectual property rights over the application
                  and its content, including but not limited to copyrights, trademarks, and trade
                  secrets, are owned and licensed by EdNeos. You agree not to copy, modify,
                  distribute, sell, or exploit any part of the application without our prior
                  written consent.</p>
                <p><b>2. Service Plans Available at EdNeos</b></p>
                <p style="text-indent: 5vw;">EdNeos offers a variety of service plans to meet the
                  diverse needs of our users. Detailed information about these plans can be found
                  in the subscription section of the application settings or on our website. Our
                  plans are classified as follows:</p>
                <p><b>2.1. Free Service Plans:</b></p>
                <p style="text-indent: 5vw;">These plans provide access to basic features of the
                  EdNeos platform and are intended for non-commercial use. They are ideal for
                  students and pupils who want to explore the basic functionalities of the
                  platform.</p>
                <p><b>2.2. Paid Service Plans:</b></p>
                <p style="text-indent: 5vw;">Paid plans allow access to advanced features and
                  additional resources. These are suitable for professional and commercial use,
                  including in educational, training, and research environments. The features and
                  limitations of each plan can vary with some separate functionalities, and the
                  user chooses the service plan (Pro or Premium subscription) according to their
                  personal preferences and needs.</p>
                <p><b>2.3. Managing Plans and Subscriptions:</b></p>
                <p style="text-indent: 5vw;">You can manage service plans and content
                  subscriptions for purchases made through external platforms (e.g., Google Play,
                  Apple App Store); management is done through these platforms.</p>
                <p><b>2.4. Payments, Cancellations, and Upgrades:</b></p>
                <p style="text-indent: 5vw;">● Paid service plans and content subscriptions
                  require upfront payment and are generally non-refundable, with exceptions
                  according to applicable legislation.</p>
                <p style="text-indent: 5vw;">● At the end of each subscription period, plans and
                  subscriptions automatically renew, and payment will be made according to the
                  recorded payment details.</p>
                <p style="text-indent: 5vw;">● Cancellations can be made before the end of the
                  current subscription period, with effect at the end of that period.</p>
                <p><b>2.5. Flexibility of Service Plans:</b></p>
                <p style="text-indent: 5vw;">● At EdNeos, you can upgrade or downgrade at any time
                  during your subscription to another paid service plan.</p>
                <p style="text-indent: 5vw;">● Upon upgrading, the change will take effect
                  immediately, subject to the payment of the upgrade fee.</p>
                <p style="text-indent: 5vw;">● Upon downgrading, the change will take place at the
                  end of the current subscription period by canceling a subscription and procuring
                  the selected one.</p>
                <p><b>2.5. Price Changes and New Service Plans:</b></p>
                <p style="text-indent: 5vw;">● EdNeos reserves the right to change prices for
                  service plans and content subscriptions. Any price change will be effective at
                  the beginning of the next subscription period.</p>
                <p style="text-indent: 5vw;">● We will inform you accordingly about any price
                  change, and you have the option to cancel the service if you do not agree with
                  the new rates.</p>
                <p><b>2.6. Taxes:</b></p>
                <p style="text-indent: 5vw;">● Displayed prices may exclude applicable taxes,
                  which will be calculated and added at the end of the transaction.</p>
                <p><b>3. User Content:</b></p>
                <p><b>3.1. User Actions:</b></p>
                <p style="text-indent: 5vw;">3.1. By using the application, you may submit or
                  upload various types of content, such as sets of flashcards, images, or other
                  materials ("User Content"). You retain all property rights over your User
                  Content.</p>
                <p><b>3.2. Privacy and Use of Content:</b></p>
                <p style="text-indent: 5vw;">Content you upload to EdNeos is accessible only to
                  you, and if you decide to provide it to someone else, you do so at your own
                  risk, and it may be used by EdNeos for the purpose of providing our
                  services.</p>
                <p><b>3.3 License Granted to EdNeos:</b></p>
                <p style="text-indent: 5vw;">By using EdNeos, you grant a limited, worldwide,
                  non-exclusive license for EdNeos to access, use, and process your content for
                  reasonable purposes, such as providing and improving services, addressing
                  technical issues, and legal compliance.</p>
                <p style="text-indent: 5vw;">EdNeos will remove your content upon account
                  termination, except for public content, which is managed according to our
                  privacy policy.</p>
                <p><b>3.4. Your Responsibility for Content:</b></p>
                <p style="text-indent: 5vw;">You are responsible for the content you upload to
                  EdNeos and must have all necessary rights for it. EdNeos does not endorse the
                  accuracy or opinions expressed in User Content.</p>
                <p><b>3.5. Monitoring User Content:</b></p>
                <p style="text-indent: 5vw;">EdNeos may review, monitor, and, at our discretion,
                  remove any content that violates our terms and conditions.</p>
                <p><b>3.6. Public User Content:</b></p>
                <p style="text-indent: 5vw;">If you choose to make your content public, you grant
                  EdNeos a broad license to use and distribute this content in various forms and
                  media.</p>
                <p><b>3.7. Verified Creators on the EdNeos Platform:</b></p>
                <p style="text-indent: 5vw;">The EdNeos platform allows verified creators to offer
                  educational content and resources to EdNeos users for free or depending on the
                  type of subscription. We will ask for your consent each time you wish to receive
                  this content.</p>
                <p><b>4. Account Security at EdNeos</b></p>
                <p><b>4.1. Your Responsibility for Account Security:</b></p>
                <p style="text-indent: 5vw;">● As an EdNeos user, you are fully responsible for
                  the access and control of your account and the devices used to access the
                  services.</p>
                <p style="text-indent: 5vw;">● It is essential to maintain the security of your
                  devices and not to disclose passwords or payment details associated with your
                  account.</p>
                <p style="text-indent: 5vw;">● You are responsible for updating and maintaining
                  the accuracy of your account information.</p>
                <p style="text-indent: 5vw;">● In case of unauthorized or fraudulent use, EdNeos
                  reserves the right to suspend or close the account for the protection of all
                  parties involved.</p>
                <p><b>4.2. Data Protection:</b></p>
                <p style="text-indent: 5vw;">EdNeos commits to protecting and processing personal
                  data in accordance with applicable legislation and our privacy policies. EdNeos
                  will only require a short name and your email. EdNeos will not collect other
                  information, nor will it ask you to provide other information.</p>
                <p><b>4.3. Term and Termination:</b></p>
                <p style="text-indent: 5vw;">● The agreement between you and EdNeos remains valid
                  as long as you use our services.</p>
                <p style="text-indent: 5vw;">● EdNeos may terminate the agreement or suspend
                  access to our services in the case of unauthorized use or non-compliance with
                  the terms of the agreement.</p>
                <p style="text-indent: 5vw;">● In case of termination, EdNeos will not be liable
                  to you and will not refund any amounts already paid.</p>
                <p><b>4.4 Warranty and Disclaimer:</b></p>
                <p style="text-indent: 5vw;">● EdNeos will make reasonable efforts to ensure that
                  services function as described on our website.</p>
                <p style="text-indent: 5vw;">● We do not guarantee that the services will be
                  continuously available or uninterrupted.</p>
                <p style="text-indent: 5vw;">● EdNeos is not responsible for problems caused by
                  hardware, software, or external components to our services.</p>
                <p><b>5. Limitation of Liability of EdNeos</b></p>
                <p><b>5.1. Scope of Responsibility:</b></p>
                <p style="text-indent: 5vw;">In accordance with applicable law, EdNeos and its
                  affiliates will not be liable for special, indirect, incidental, punitive,
                  exemplary, or consequential damages, including data loss, business, or profits.
                  The total liability of EdNeos in connection with this agreement is limited to
                  the amount you have paid to EdNeos in the last 12 months.</p>
                <p><b>5.2. Exceptions to Limitation of Liability:</b></p>
                <p style="text-indent: 5vw;">This limitation does not exclude EdNeos's
                  responsibility for fraud, fraudulent statements, death or bodily injury due to
                  negligence, or other serious cases provided by law.</p>
                <p><b>5.3. Specific Apple Terms for iOS Users:</b></p>
                <p style="text-indent: 5vw;">When using the EdNeos application on iOS devices, it
                  is subject to Apple's terms and conditions, including limited rights to use the
                  application on Apple devices.</p>
                <p><b>5.4. Specific Apple Terms for iOS Users of the EdNeos Application:</b></p>
                <p style="text-indent: 5vw;">● Apple Inc. is not a party to these Terms and does
                  not own and is not responsible for the EdNeos application.</p>
                <p style="text-indent: 5vw;">● Apple does not provide warranties for the EdNeos
                  application, except for the possibility of refunding the purchase price, if
                  applicable.</p>
                <p style="text-indent: 5vw;">● Apple is not responsible for the maintenance or
                  other support services for the EdNeos application and will not be liable for any
                  claims, losses, liabilities, damages, costs, or expenses related to the
                  application.</p>
                <p style="text-indent: 5vw;">● Any questions or complaints related to the use of
                  the EdNeos application, including those concerning intellectual property rights,
                  should be directed to EdNeos.</p>
                <p style="text-indent: 5vw;">● The license granted to you by these Terms is
                  limited to the use of the EdNeos application on an Apple device running iOS,
                  owned or controlled by you, in accordance with the Usage Rules set in the Apple
                  App Store Terms and Conditions.</p>
                <p><b>6. Miscellaneous:</b></p>
                <p style="text-indent: 5vw;">This agreement represents all the terms agreed upon
                  between you and EdNeos and supersedes any previous agreements related to its
                  subject.</p>
                <p><b>6.1. Communication Opportunities:</b></p>
                <p style="text-indent: 5vw;">Our technical team works daily to ensure the best
                  accuracy, completeness, and reliability for all users, and we encourage you to
                  notify us of any issues you may encounter while using the EDNEOS application or
                  web platform at</p>
                <p style="text-indent: 10vw;">hello@edneos.com.</p>
                <p><b>6.2. Changes to the Terms:</b></p>
                <p style="text-indent: 5vw;">EdNeos reserves the right to modify the terms of this
                  agreement. Any changes will be communicated accordingly.</p>
                <p><b>6.3. Applicable Law and Jurisdiction:</b></p>
                <p style="text-indent: 5vw;">● This agreement is governed by the laws of Romania.
                  Any dispute will be resolved in the courts of Romania.</p>
                <p><b>7. EdNeos Definitions</b></p>
                <p style="text-indent: 5vw;">"Acceptable Use Policy" means the EdNeos acceptable
                  use policy, which is updated periodically and available on our website.</p>
                <p style="text-indent: 5vw;">"Administrator" means the person designated by your
                  organization to manage the EdNeos accounts of that organization, if
                  applicable.</p>
                <p style="text-indent: 5vw;">"Agreement" represents these Terms, the Acceptable
                  Use Policy, any chosen Service Plan, and, if applicable, any Enterprise
                  Agreement.</p>
                <p style="text-indent: 5vw;">"Application" means the mobile applications and
                  software of EdNeos.</p>
                <p style="text-indent: 5vw;">"Apple" refers to Apple Inc., in the context of using
                  EdNeos applications on iOS devices.</p>
                <p style="text-indent: 5vw;">"Children" means minors under the age of 13 in the
                  USA or under the age of 16 in other jurisdictions.</p>
                <p style="text-indent: 5vw;">"Code" refers to codes or other promotional offers
                  provided by EdNeos for access to paid service plans or content
                  subscriptions.</p>
                <p style="text-indent: 5vw;">"Consumer" means an individual who uses EdNeos
                  services for non-commercial purposes.</p>
                <p style="text-indent: 5vw;">"Content Subscriptions" represent subscriptions to
                  premium educational content libraries offered by EdNeos, in addition to the
                  chosen Service Plan.</p>
                <p style="text-indent: 5vw;">"Enterprise Agreement" refers to a separate contract
                  between EdNeos and your associated organization.</p>
                <p style="text-indent: 5vw;">"EdNeos" is the name of the company that provides the
                  EdNeos services.</p>
                <p style="text-indent: 5vw;">"EdNeos Brands" refers to all trademarks, service
                  marks, trade names, logos, and any other distinctive brand features of
                  EdNeos.</p>
                <p style="text-indent: 5vw;">"License" means the right of use granted according to
                  section 3 of these Terms.</p>
                <p style="text-indent: 5vw;">"Platform" means the websites and applications of
                  EdNeos where services are available.</p>
                <p style="text-indent: 5vw;">"Public User Content" refers to content created by
                  users and made available to the public at large through the EdNeos services.</p>
                <p style="text-indent: 5vw;">"Resources" means the information, services,
                  products, and tools offered through EdNeos.</p>
                <p style="text-indent: 5vw;">"Resources" means the information, services,
                  products, and tools offered through EdNeos.</p>
                <p style="text-indent: 5vw;">"Services" means the educational services and other
                  services offered through EdNeos.</p>
                <p style="text-indent: 5vw;">"Subscription Period" means the duration for which a
                  Service Plan or Content Subscription is active.</p>
                <p style="text-indent: 5vw;">"Terms" refer to the general terms and conditions of
                  using EdNeos services.</p>
                <p style="text-indent: 5vw;">"Third-Party Applications" means services and
                  software provided by third parties, which are integrated into EdNeos
                  services.</p>
                <p style="text-indent: 5vw;">"Trial" represents trial periods for paid Service
                  Plans or Content Subscriptions.</p>
                <p style="text-indent: 5vw;">"User Content" means all data, content, and
                  information uploaded by users within the EdNeos services.</p>
                <p style="text-indent: 5vw;">"Vanity URL" represents a customized URL for a user
                  or organization within the EdNeos platform.</p>
                <p style="text-indent: 5vw;">"Verified Creator" refers to an EdNeos user who has
                  been verified according to our standards.</p>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>

    </div>
  </div>
</template>

<style>
.Terms_container__ozi61, .Privacy_container__TkhHO {
  height: calc(100vh - 174px);
  overflow: scroll;
}

.profile-image {
  display: inline-flex;
  margin-right: 12px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
}
.profile-image img{
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.profile-modal {
  .headline-regular-16 {
    vertical-align: bottom;
    display: flex;
    align-items: end;

  }

  .headline-regular-16 img {
    margin-right: 10px;
  }

  .headline-regular-16 .unicon {
    margin-right: 12px;
  }
}

.vue-popover {
  left: -168px !important;
  top: 296px !important;
}
.form-control.input-invalid{
  border-color: rgb(212, 40, 4);
}
</style>
