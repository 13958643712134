<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>

import Unicon from 'vue-unicons/dist/vue-unicons-vue2.umd';
import {
  uniBell, uniBookOpen, uniCarWash, uniCheckCircle, uniCreditCard, uniEstate,
  uniPresentationCheck, uniQuestionCircle, uniSignOutAlt, uniUserCircle, uniUsersAlt,
  uniWall, uniChart, uniChartLine, uniSmileWink, uniSetting, uniFolderOpen,
  uniPlayCircle, uniTrash, uniTrashAlt, uniAngleLeftB, uniLink, uniEditAlt,
  uniBookmark, uniSearch, uniEnvelope, uniMessage, uniSave, uniNewspaper,
  uniCalender, uniClockFive, uniEllipsisV, uniPlus, uniArrowUp, uniArrowDown,
  uniUserPlus, uniEye, uniMultiply, uniCreateDashboard, uniCamera, uniImage,
  uniAngleLeft, uniAngleRight, uniInfoCircle, uniTextFields, uniEdit, uniComparison,
  uniAbacus, uniFileCheckAlt, uniCheck, uniTimes, uniStopwatch, uniExclamationTriangle,
  uniBalanceScale, uniBrushAlt, uniSmileWinkAlt, uniConstructor, uniBrowser, uniMonitorHeartRate,
  uniSubject, uniSwatchbook, uniMicrophone, uniListUl, uniImagePlus, uniArrowRight,
  uniSpinnerAlt, uniCloudUpload, uniRedo, uniMicrophoneSlash, uniPause, uniWallet,
  uniMoneyWithdraw, uniAngleDown, uniRobot, uniEyeSlash, uniGlobe, uniShieldExclamation, uniFileAlt,
  uniUser, uniChat, uniShoppingBag, uniLightbulb, uniArrowLeft, uniLockAlt, uniUnlockAlt,
  uniFileImport,
} from 'vue-unicons/dist/icons';
import Vue from 'vue';

Unicon.add([uniEstate,
  uniPresentationCheck, uniUsersAlt, uniUserCircle, uniBookOpen, uniCarWash,
  uniQuestionCircle, uniCheckCircle, uniCreditCard, uniSignOutAlt, uniBell,
  uniWall, uniChart, uniChartLine, uniSmileWink, uniSetting, uniFolderOpen,
  uniPlayCircle, uniTrash, uniTrashAlt, uniAngleLeftB, uniLink, uniEditAlt,
  uniBookmark, uniSearch, uniEnvelope, uniMessage, uniSave, uniNewspaper,
  uniCalender, uniClockFive, uniEllipsisV, uniPlus, uniArrowUp, uniArrowDown,
  uniUserPlus, uniEye, uniMultiply, uniCreateDashboard, uniCamera, uniImage,
  uniAngleLeft, uniAngleRight, uniInfoCircle, uniTextFields, uniEdit, uniComparison,
  uniAbacus, uniFileCheckAlt, uniCheck, uniTimes, uniStopwatch, uniExclamationTriangle,
  uniBalanceScale, uniBrushAlt, uniSmileWinkAlt, uniConstructor, uniBrowser, uniMonitorHeartRate,
  uniSubject, uniSwatchbook, uniMicrophone, uniListUl, uniImagePlus, uniArrowRight,
  uniSpinnerAlt, uniCloudUpload, uniRedo, uniMicrophoneSlash, uniPause, uniWallet,
  uniMoneyWithdraw, uniAngleDown, uniRobot, uniEyeSlash, uniGlobe, uniShieldExclamation, uniFileAlt,
  uniUser, uniChat, uniShoppingBag, uniLightbulb, uniArrowLeft, uniLockAlt, uniUnlockAlt,
  uniFileImport,
]);
Vue.use(Unicon);
export default {
  name: 'App',
};

</script>

<style lang="scss">
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
  @import 'assets/sass/style.vue';
</style>
