const locale = {
  GENERAL: {
    ALL: 'All',
    BACK: 'Back',
    COPY_LINK: 'Copy link',
    COPIED_LINK: 'Link copied',
    DEPARTAMENT: 'Department',
    SAVE: 'Save',
    BASIC_INFO: 'Basic info',
    PERFORMANCE: 'Performance statistics',
    DELETE: 'Delete',
    DOWNLOAD_EXCEL: 'Download excel',
    CHANGE: 'Change',
    HOME: 'Home',
    MANAGEMENT: 'Management',
    CONTENT: 'Ask AI',
    FILE_AI: 'File AI',
    TAGS: 'Subjects with AI',
    NEWS: 'Add news',
    EMPLOYED: 'Assign mentors',
    LOGOUT: 'Logout',
    AFFILIATION_PROGRAM: 'Rewards Program',
    AI_CARDS: 'Subject Store',
    DRAG_AND_DROP_FILES: 'Drag and drop files here',
    QUESTION: 'Question',
    CARDS_LOWERCASE: 'learning cards',
    SAVED_SUCCESSFULLY: 'Saved Successfully',
    ACCESS_TO_ALL: 'Oferă acces general pentru toți utilizatorii',
    ACCESS_FOR_ALL: 'Acces pentru toți utilizatorii',
    ACCESS_FOR_ME: 'Acces doar pentru mine',
    PASSWORD_MUST_CONTAIN: 'Parola trebuie sa contină:',
    AR_LEAST_6_CHARACTERS: 'cel puțin 6 caractere',
    AR_LEAST_ONE_LETTER: 'cel puțin o literă',
    AR_LEAST_ONE_NUMBER: 'cel puțin un număr',
    AR_LEAST_ONE_TOKEN: 'cel puțin un simbol',
    EMAIL_CODE: 'Codul primit pe email',
    NOT_SELECTED: 'Neselectat',
    INFO_HOME: 'Discover the secrets of deep learning integrated by EdNeos by watching this informative video: [link video]',
    INFO_MANAGEMENT: 'Create a dedicated group for your training participants by registering their names and emails, and monitor the results in real time.',
    INFO_TAGS: 'Add a domain, develop a concept, create learning cards, and continue exploring, all with the help of artificial intelligence.',
    INFO_CARDS: 'Introduce a concept and instantly receive a set of learning cards. You can find the new created domain and concept in the "Subject Store" section.',
    INFO_CONTENT: 'Ask a question using voice command, and our AI assistant will help you save the answer to your associated learning card.',
    INFO_NEWS: 'Keep a constant communication with your audience by scheduling automatic notification delivery at key moments.',
    INFO_MENTORS: 'Keep a constant communication with your audience by scheduling automatic notification delivery at key moments.',
    INFO_AFFILIATION: 'Thank you for your contribution! To express our gratitude,  we prepared a series of special rewards and benefits just for you.',
    ACCESS_TO_PREMIUM: 'Give access to Premium cards and earn money',
    ACCESS_FOR_ALL_TO_PREMIUM: 'Access for all users (minimum 300 learning cards)',
  },
  NEWS: {
    ADD_NEWS: 'Add new',
    PUBLISHING_DATE: 'Date and Time of Posting',
    FIELDS: {
      DEPARTAMENT: 'Manage access for groups created in Department',
      GROUP: 'Group',
      TITLE: 'Title (maximum 200 characters)',
      DESCRIPTION: 'Description (maximum 500 characters)',
      IMAGES: 'Images',
      LINK: 'Your URL',
    },
  },
  DEPARTAMENTS: {
    PER_MONTH: 'Per month',
    LIST: 'List departments',
    LIST_GROUP: 'Group list',
    ADD_GROUP: 'Add group',
    ADD_STUDENT: 'Add student',
    EDIT_STUDENT: 'Edit student',
    STUDENTS_SUCCESS: 'Stundets Succcess',
    ADD_DEPARTAMENT: 'Add Department',
    GROUPS: 'Groups',
    GROUP: 'Group',
    STUDENTS: 'Students',
    ADD_NEW_USER: 'Add new user',
    PROGRESS: 'Progress',
    LEARNING_STAGES: 'Learning stages',
    NO: 'No',
    STUDENT: 'Student',
    RAPIDITY: 'Rapidity',
    ACCURACY: 'Accuracy',
    MEMORIZATION: 'Knowledge Level',
    ELEMENTARY: 'Beginner',
    MEDIUM: 'Intermediate',
    EXPERT: 'Expert',
    APPLICATION: 'Practical Application',
    LIFEHACK: 'Daily Life Application',
    COMPLETE_AND_SAVE: 'Complete and save',
    IMPORT_STUDENTS: 'Import students',
    FIELDS: {
      TITLE: 'Title',
      DESCRIPTION: 'Description',
      ADMIN: 'Administrator',
      SEARCH: 'Search',
    },
  },
  EMPLOYEE: {
    EMPLOYEE: 'Employees',
    ADD_EMPLOYEE: 'Add employee',
    NO: '№',
    NAME: 'Name',
    DATA: 'Date',
    EMAIL: 'Email',
    STATUS: 'Status',
    CHAT: 'Message',
    MORE: 'More',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    FIELDS: {
      SEARCH: 'Search',
      LAST_NAME: 'Nume',
      FIRST_NAME: 'Prenume',
      EMAIL: 'Email',
      TYPE: 'Tip',
    },
  },
  ERRORS: {
    DEFAULT_ERROR_MESSAGE: 'Ops, an error occurred',
    AUTH_REQUIRED: 'Authentication required',
    AUTH_INVALID: 'Invalid authentication. Retry!',
    TOKEN_INVALID: 'Invalid token',
    AUTH_FAILED: 'Authentication failed',
    NO_GROUPS: 'No groups found!',
    FILE_FORMAT: 'File format is incorrect!',
    FILE_EMPTY: 'File contains no lines!',
  },
  AUTH: {
    LOGIN_TO_EDNEOS: 'Log in to EdNeos',
    LOGIN_BUTTON: 'Log in',
    FORGOT: 'Forgot',
    PASSWORD: 'password',
    DONT_HAVE_ACCOUNT: 'Don\'t have an account?',
    CREATE_ACCOUNT: 'Create Account',
    LOGIN_WITH_GOOGLE: 'Log in with Google',
    LOGIN_ERROR: 'Sorry, there was an error!',
    RESET_PASSWORD: 'Reset Password',
    RESET_PASSWORD_INFO: 'Instructions will be sent to your Email',
    SEND_INSTRUCTIONS: 'Sent instruction',
    CHECK_INBOX: 'Instruction have been sent to your Email',
    CHECK_INBOX_INFO: 'Instruction have been sent to your Email',
    TRY_AGAIN: 'Try Again',
    OOPS: 'Oh no! Something didn\'t work as expected',
    OOPS_INFO: 'There is no associated with your Email',
    CREATE_PASSWORD: 'Create password',
    CREATE_PASSWORD_INFO: 'Your new password must be different',
    ERROR_PASSWORD: 'Different passwords!',
    SUCCES: 'Great! It worked.',
    SUCCES_INFO: 'Your new password has been successfully saved',
    CREATE_MENTOR_ACCOUNT: 'Create a Mentor/Teacher Account',
    CREATE_MENTOR_ACCOUNT_WITH_GOOGLE: 'Sign Up with Google',
    MENTOR_ACCOUNT: 'Mentor/Teacher Account',
    MENTOR_ACCOUNT_DESCRIPTION: 'Passionate about teaching? Edneos turns your passion into impact, providing tools for teaching that leaves a lasting and deep impression.',
    MENTOR_INFO_1: 'Improved student performance through continuous monitoring',
    MENTOR_INFO_2: 'Support in creating interactive educational content with AI assistance',
    MENTOR_INFO_3: 'Reliable solution for consolidating and protecting students\' knowledge',
    MENTOR_INFO_4: 'Utilize interactive learning and voice learning flashcards',
    SCHOOL_ACCOUNT: 'Institutional Account',
    SCHOOL_ACCOUNT_DESCRIPTION: 'We support institutions aiming to integrate technology in education for profound and long-term learning.',
    SCHOOL_INFO_1: 'Saving time and training costs',
    SCHOOL_INFO_2: 'Efficient tools for knowledge consolidation and protection',
    SCHOOL_INFO_3: 'Innovative monitoring of students\' educational progress',
    SCHOOL_INFO_4: 'Use AI and voice recognition flashcards for exploration',
    SUBMIT_FOR_SCHOOL_ACCOUNT: 'Submit for Institutional Account',
    USER_ACCOUNT: 'User Account',
    USER_ACCOUNT_DESCRIPTION: 'Curious to learn something new? Download the Edneos app and explore a new world of knowledge!',
    USER_INFO_1: 'Long-term knowledge retention and consolidation',
    USER_INFO_2: 'Access to a self-personalized learning system',
    USER_INFO_3: 'Efficiency and autonomy in managing knowledge',
    USER_INFO_4: 'Use AI and voice recognition flashcards for exploration',
    DOWNLOAD_ON_APP_STORE: 'Download on the App Store',
    DOWNLOAD_ON_GOOGLE_PLAY: 'Download it on Google Play',
    AGREE: 'Agree',
    TERMS: 'Terms of Service, Privacy Policy.',
    ALREADY_HAVE_ACCOUNT: 'Already have an account?',
    CREATE_SCHOOL_ACCOUNT: 'Registration for Institutional Account',
    SING_UP: 'Sign up',
  },
  USER: {
    ACCOUNT_SETTINGS: 'Account Settings',
    EXTRAS: 'Extras',
    INVITE_FRIENDS_ON_EDNEOS: 'Invite friends to Edneos',
    EDNEOS_TIPS: 'EdNeos Tips',
    SPECIAL_OFFER: 'SPECIAL OFFER!',
    SPECIAL_OFFER_DETAILS: 'Share the link in any way and earn 10% of any payment this user makes for 1 year.',
    UPGRADE_NOW: 'Start now!',
    MY_BENEFITS: 'My benefits',
    FEEDBACK: 'Feedback',
    SETTINGS: 'Settings',
    MY_PROFILE: 'My Profile',
    MANAGE_ACCOUNT: 'Manage account',
    TERMS_AND_CONDITIONS: 'Terms and conditions consent',
    PRIVACY_POLICY: 'Privacy policy',
    DELETE_ACCOUNT: 'Delete account',
    REMOVE_FROM_GROUP: 'Remove from group',
    FIELDS: {
      EMAIL: 'Email',
      PASSWORD: 'Password',
      PASSWORD_CONFIRM: 'Password confirm',
      FULL_NAME: 'Full name',
    },
  },
  TAGS: {
    CATEGORIES: 'Domains',
    CATEGORY: 'Domain',
    SELECT_CATEGORY: 'Choose domain',
    SELECT_TAG: 'Select subject',
    ADD_CATEGORY: 'Add field',
    ADD_CARD: 'Add learning card',
    TAGS: 'Subjects',
    TAG: 'Subject',
    ADD_TAG: 'Add subject',
    FLASH_CARD: 'Flash card',
    TEXT_ANSWER: 'Text answer',
    VOICE_ANSWER: 'Voice AI',
    MULTIPLE_ANSWER: 'Multiple answers',
    ADD_ANSWER: 'Add answer',
    ANSWER_RIGHT: 'Add correct answer',
    ANSWER_WRONG: 'Add wrong answer',
    CARDS: 'Learning Cards',
    GENERATE_AI_CARDS: 'EdNeos is ready to generate a subject with learning cards based solely on your request. Let’s start!',
    HAVE: 'Ai',
    FREE_TRIAL: 'Credits for AI-Generated Text',
    WHAT_SUBJECT_WOULD_YOU_LIKE_TO_LEARN: 'What Subject Would You Like to Learn?',
    I_THINK: 'I will soon suggest a topic and a category.',
    SUBJECT: 'Subject',
    GENERATE_10_CARD: 'Generate 10 learning cards',
    CARDS_GENERATED: 'I will create 10 learning cards, a concept, and a category in a maximum of 30 seconds. You can find them in the menu under Subject Story',
    CREATE_MANUAL: 'Create manual',
    GENERATE_WITH_AI: 'Generate with Ai',
    SELECT_TOPICS: 'Selected topics',
    SELECT_CARD_TYPE: 'Choose learning card type',
    ALL_STEPS: 'All stages',
    CREATE_WITH_AI: 'Create with AI',
    ADD_LEARNING_CARD: 'Add learning card',
    ELEMENTAR_CARD_PROGRESS: 'Elementar card progress',
    VIEW_ANSWER: 'View answer',
    ANSWER: 'Answer',
    WHAT_THINK_DIFFICULT_LEVEL_FOR_CARD: 'What do you think the difficulty level of the card is?',
    EASY: 'Easy',
    HARD: 'Hard',
    CONTINUE: 'Continue',
    SEND_ANSWER: 'Send answer',
    FANTASTIC: 'Fantastic',
    YOU_ANSWERED_CORRECTLY: 'you answered correctly',
    WITH_REGRET: 'with regret',
    YOU_ANSWERED_WRONG: 'you answered wrong',
    THE_CORRECT_ANSWER_IS: 'The correct answer is:',
    MY_CARDS: 'My learning cards',
    FIELDS: {
      CATEGORY: 'Category',
      CATEGORY_NAME: 'Category name',
      TAG_NAME: 'Name (maximum 200 characters)',
      DEPARTAMENT: 'Departments',
      GROUP: 'Group',
      YOUR_URL: 'Your URL',
      VIDEO: 'Video',
      FILE: 'Load pdf (only one)',
      LEARNING_STEP: 'Choose learning step',
      QUESTION_TITLE: 'Add question',
      ANSWER: 'Add answer',
    },
  },
  CHAT: {
    START_NEW_CHAT: 'Start a new conversation',
    MAIN_TITLE: 'Content creation',
    TITLE: 'AI Assistant',
    NEW_CHAT: 'New chat',
    TYPE_HERE: 'Type here',
    SELECT_GPT: 'Create cards with GPT',
    SELECT_PDF: 'Create cards with PDF upload',
    UPLOAD_FILE: 'Upload a file',
    GPT_GENERATOR: 'GPT Generator',
    GENERATE_CARDS: 'Generate cards',
    MESSAGES_REMAIN: 'Mesaje rămase',
    FIELDS: {
      CONVERSATION_NAME: 'Conversation name',
    },
  },
  AFFILIATION: {
    TITLE_1: '1.Immediate rewards:',
    TITLE_2: '2.Adding users to created groups:',
    TITLE_3: '3.Recommendations made through links:',
    TITLE_4: '4.Earn money from created subjects:',
    TEXT_1_1: 'By sharing this link, you will quickly and easily obtain an additional package of 50 messages and 20 attempts for AI content generation for each person who registers',
    TEXT_2_1: 'Invite your students to your study groups by entering their email addresses.',
    TEXT_2_2: 'Maintain a direct connection, monitor progress, and track detailed statistics.',
    TEXT_2_3: 'For each student eager to learn, you will benefit from 10% of their payments for additional materials.',
    TEXT_3_1: 'Invite your friends on any social network, in groups, direct messages, and comments, using the direct link or our QR code.',
    TEXT_3_2: 'See how many people have joined and the revenue generated by them.',
    TEXT_3_3: 'Those eager to learn will pay for additional materials, and you will benefit from 10% of their payments.',
    TEXT_4_1: 'Would you like to discover ways to monetize the content you create? Don\'t hesitate to contact us through the link below, and we will show you how you can get paid for each user access.',
    RESIDENCE_TABLE: 'Attendance Table',
    MODAL: 'Methods',
    NO_OF_USERS: 'Number of Users',
    NO_OF_PAYMENTS: 'Number of Payments',
    TOTAL_REVENUE: 'Total Revenue',
    INCOME_AVAILABLE: 'Available Revenue',
    POSIBILITY_OF_USE: 'Usage Possibility',
    ADD_TO_GROUPS: 'Add to Groups',
    SEND_THE_LINK: 'Send Link',
    WITHDRAW_MONEY: 'Withdraw Money',
    MINIMUM_200: 'minimum 200 euros',
    PAYMENT_FOR_1000_MESSAGES: 'Payment for one thousand messages: 9 euros',
    WITHDRAW: 'Withdraw',
    PAY: 'Pay',
  },
};
module.exports = { locale };
